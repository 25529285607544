export class LoadEmployees {
  public static readonly type = '[Employees] Load employees';

  public constructor() {}
}

export class LoadEmployeesByDealer {
  public static readonly type = '[Employees] Load employees by dealer';

  public constructor(public dealerId?: string) {}
}
