import { Component } from '@angular/core';
import { DealerGroupsState } from '@core/states/dealer-groups';
import { FiltersState, ResetFilters } from '@core/states/filters';
import { Select, Store } from '@ngxs/store';
import { FilterType } from '@shared/enums';
import { DealerGroup } from '@shared/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Select(DealerGroupsState.dealerGroups)
  public dealerGroups$!: Observable<DealerGroup[]>;
  public filterTypes = FilterType;

  constructor(private store: Store) {}

  public resetFilter(): void {
    this.store.dispatch(new ResetFilters());
  }

  public showFilter(filterType: FilterType): Observable<boolean> {
    return this.store.select(FiltersState.hasSelectedParent(filterType));
  }
}
