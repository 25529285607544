import { Component } from '@angular/core';
import { DashboardState, FiltersState } from '@core/states';
import { areaTree } from '@misc/filter-node';
import { Select } from '@ngxs/store';
import { FilterType, LoadingData } from '@shared/enums';
import { Employee, Filter } from '@shared/models';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-employee-data',
  templateUrl: './employee-data.component.html',
  styleUrls: ['./employee-data.component.scss'],
})
export class EmployeeDataComponent {
  @Select(FiltersState.activeFiltersArray)
  public activeFilters$!: Observable<Filter[]>;

  @Select(DashboardState.employeeWithLearningPlans)
  public employeeWithCertification$!: Observable<Employee>;

  @Select(DashboardState.isLoading(LoadingData.employeeData))
  public isLoading$!: Observable<boolean>;

  public get hasSelectedEmployee(): Observable<boolean> {
    return this.activeFilters$.pipe(
      map((filters: Filter[]): boolean => areaTree.findBranchEnding(filters) === FilterType.Employee),
    );
  }
}
