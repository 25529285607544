import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableFilterOption } from '@shared/classes';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent<T> implements OnInit {
  @Input() public filters: TableFilterOption<T>[] = [];

  @Output() public filter: EventEmitter<TableFilterOption<T>> = new EventEmitter<TableFilterOption<T>>();

  public isOpened = false;
  public selectedFilter: TableFilterOption<T> | null = null;

  public ngOnInit(): void {
    this.selectedFilter = this.filters[0];
  }

  public onSelect(filter: TableFilterOption<T>): void {
    this.selectedFilter = filter;
    this.isOpened = false;
    this.filter.emit(filter);
  }
}
