import { FilterSelectionType, FilterType } from '@shared/enums';
import { IFilterable, IFilterSettings } from '@shared/interfaces';

export class DealerProductGroup implements IFilterable {
  public id!: string;
  public name!: string;

  public selected = false;

  public filterSettings: IFilterSettings = {
    type: FilterType.DealerProductGroup,
    selectionType: FilterSelectionType.Multi,
  };
}
