import { FilterSelectionType, FilterType } from '@shared/enums';
import { IFilterable, IFilterSettings } from '@shared/interfaces';
import { Dealer } from '@shared/models/dealer.model';
import { Entity } from '@shared/models/entity.model';
import { Type } from 'class-transformer';

export class Region implements IFilterable {
  @Type(() => Dealer)
  public dealers?: Dealer[];
  @Type(() => Entity)
  public entity?: Entity;

  public id!: string;
  public entityId!: string;
  public name!: string;
  public selected = false;

  public filterSettings: IFilterSettings = {
    type: FilterType.Region,
    selectionType: FilterSelectionType.Single,
  };
}
