<div class="container" #mainContainer>
  <div class="row">
    <div class="col mt-5">
      <h1>Login</h1>
    </div>
  </div>
  <app-loading-indicator *ngIf="isBusy"></app-loading-indicator>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!isBusy">
    <div class="row mb-2">
      <div class="col">
        <div class="form-group required row">
          <label class="col-2 mr-2" for="username">Gebruikersnaam</label>
          <input class="col-3" formControlName="username" id="username" />
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <div class="form-group required row">
          <label class="col-2 mr-2" for="password">Wachtwoord</label>
          <input class="col-3" type="password" formControlName="password" id="password" />
        </div>
      </div>
    </div>
    <div class="footer">
      <button type="submit" [disabled]="form.invalid">Inloggen</button>
    </div>
  </form>
</div>
