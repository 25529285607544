import { Certification } from '@shared/models/certification.model';
import { Type } from 'class-transformer';

export class LearningPlan {
  @Type(() => Certification)
  public certification?: Certification;

  public id!: string;
  public name!: string;
  public code!: string;
  public level?: number;
}
