import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { faSortAsc, faSortDesc, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { TableData, TableFilter, TableFilterOption, TableHeader } from '@shared/classes';
import { exportmeExcel } from 'excel-ent';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T extends TableData<RowData>, RowData = Record<string, any>> implements OnInit, OnChanges {
  @Input() title!: string;
  @Input() columns!: T;
  @Input() data!: RowData[];
  @Input() filters: TableFilter<RowData>[] = [];
  @Input() horizontalScroll = false;
  @Input() enableExportToExcel = false;
  @Input() onRowClicked?: (row: RowData) => void;
  @Input() tooltip?: string | TemplateRef<any>;
  @Input() tooltipPlacement: string = 'auto';
  @Input() tooltipClass: string = '';

  public sortedData!: RowData[];

  public sortKey: string | null = null;
  public sortDirection?: 'asc' | 'desc' | null;

  public activeFilters: { [key: string]: TableFilterOption<RowData> } = {};

  public get icon(): IconDefinition {
    return this.sortDirection === 'asc' ? faSortAsc : faSortDesc;
  }

  ngOnInit(): void {
    this.sortedData = this.data;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('data' in changes) {
      this.sortedData = this.data;
    }
  }

  public isSortingBy(column: TableHeader<RowData>): boolean {
    return this.sortKey === column.sortKey && column.sortable;
  }

  public filter(name: string, filter: TableFilterOption<RowData>): void {
    this.activeFilters[name] = filter;

    this.sortedData = this.data.filter((item: RowData): boolean => {
      return Object.values(this.activeFilters).every((filter: TableFilterOption<RowData>): boolean =>
        filter.filter(item),
      );
    });
  }

  public sort(sortKey: string | null): void {
    if (this.sortKey !== sortKey) {
      this.sortKey = sortKey;
      this.sortDirection = null;
    }

    switch (this.sortDirection) {
      case 'asc':
        this.sortDirection = null;
        break;
      case 'desc':
        this.sortDirection = 'asc';
        break;
      default:
        this.sortDirection = 'desc';
        break;
    }

    this.sortKey = this.sortDirection !== null ? sortKey : null;

    if (this.sortDirection === null || this.sortKey === null) {
      this.sortedData = this.data;
      return;
    }

    this.sortedData = [...this.data].sort((a: RowData, b: RowData): number => {
      const valueA = this.columns.activeColumnMap[this.sortKey!].bobTheValueBuilder(a) as string | number | Date | null;
      const valueB = this.columns.activeColumnMap[this.sortKey!].bobTheValueBuilder(b) as string | number | Date | null;

      if (valueA === valueB) return 0;
      if (valueA == null && valueB != null) return 1;
      if (valueA != null && valueB == null) return -1;
      if (this.sortDirection === 'asc') {
        return valueA! > valueB! ? -1 : 1;
      }
      return valueB! > valueA! ? -1 : 1;
    });
  }

  public exportToExcel(): void {
    const data = this.data.map((item: RowData) => {
      const row: Record<string, any> = {};
      Object.keys(this.columns.activeColumnMap).forEach((key: string) => {
        row[key] = this.collapseWhitespace(
          this.columns.activeColumnMap[key]
            .excelExportValue(item, this.columns.activeColumnMap[key].bobTheValueBuilder(item))
            ?.toString() ?? '',
        );
      });
      return row;
    });

    exportmeExcel(
      data,
      this.title,
      {
        type: 'download',
      },
      {
        headerStyle: {
          font: {
            bold: true,
          },
        },
        rowHeights: [32],
      },
    );
  }

  private collapseWhitespace(str: string): string {
    return str.replace(/\s+/g, ' ');
  }
}
