<div class="d-flex mb-3">
  <div style="margin-right: auto" class="d-flex">
    <h2 class="my-auto">{{ title }}</h2>
    <app-tooltip-icon
      *ngIf="!!tooltip"
      [tooltip]="tooltip"
      [placement]="tooltipPlacement"
      [tooltipClass]="tooltipClass"></app-tooltip-icon>
  </div>
  <app-dropdown *ngFor="let f of filters" [filters]="f.filters" (filter)="filter(f.name, $event)"></app-dropdown>
  <app-icon-button
    *ngIf="enableExportToExcel"
    (click)="exportToExcel()"
    src="/assets/excel.svg"
    alt="Export to Excel"></app-icon-button>
</div>

<div [class.table-wrapper-horizontal-scrollable]="horizontalScroll" *ngIf="data.length > 0; else noData">
  <table class="table table-hover" *ngIf="columns">
    <thead>
      <tr>
        <th
          *ngFor="let column of columns.active"
          [colSpan]="column.children.length"
          [class.pointer]="column.children.length === 0"
          (click)="column.sortable ? sort(column.sortKey) : false"
          [class.text-center]="column.alignedCenter">
          <fa-icon
            *ngIf="column.alignedCenter && column.sortable"
            class="hidden"
            size="lg"
            [fixedWidth]="true"
            [icon]="icon" />
          <ng-container *ngIf="!column.headerIsMultiline">{{ column.title }}</ng-container>
          <ng-container *ngIf="column.headerIsMultiline">
            <div class="preserve-breaks">{{ column.title }}</div>
          </ng-container>
          <fa-icon
            [class.hidden]="!isSortingBy(column) || !column.sortable"
            size="lg"
            [fixedWidth]="true"
            [icon]="icon" />
        </th>
      </tr>
      <tr>
        <th
          *ngFor="let column of columns.activeColumns"
          [class.text-start]="column.alignedStart"
          [class.text-center]="column.alignedCenter"
          [class.text-end]="column.alignedEnd"
          [class.pointer]="column.isChild"
          (click)="column.sortable ? sort(column.sortKey) : false"
          [class.is-sorted]="isSortingBy(column)">
          <ng-container *ngIf="column.isChild">
            <fa-icon *ngIf="column.alignedCenter" class="hidden" size="lg" [fixedWidth]="true" [icon]="icon" />
            {{ column.title }}
            <fa-icon [class.hidden]="!isSortingBy(column)" size="lg" [fixedWidth]="true" [icon]="icon" />
          </ng-container>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let row of sortedData"
        [class]="onRowClicked ? 'clickable-row' : null"
        (click)="onRowClicked ? onRowClicked(row) : null">
        <td
          *ngFor="let column of columns.activeColumns"
          [class.text-start]="column.alignedStart"
          [class.text-center]="column.alignedCenter"
          [class.text-end]="column.alignedEnd"
          [class.preserve-breaks]="column.preserveBreaks"
          [class.no-wrap]="column.noWrap">
          <div *ngIf="!column.template" [style.color]="column.textColorCallback?.(row)">
            {{ column.aliceTheDisplayValueBuilder(row, column.bobTheValueBuilder(row)) }}
          </div>
          <div *ngIf="column.template">
            <ng-container
              *ngTemplateOutlet="
                column.template;
                context: { $implicit: column.bobTheValueBuilder(row) }
              "></ng-container>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #noData>No data found for the given filters.</ng-template>
