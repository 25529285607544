import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import {
  DashboardStateModel,
  LoadDealerGroupsWithCertifications,
  LoadDealersCertificationData,
  LoadDealerWithCertifications,
  LoadEmployeesWithCertifications,
  LoadEmployeeWithCertifications,
  SetPageLoadingFinished,
} from '@core/states/dashboard';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import { LoadingData } from '@shared/enums';
import {
  Certification,
  Dealer,
  DealerCertificationData,
  DealerGroup,
  Employee,
  EmployeeCourse,
  EmployeeLearningPlan,
} from '@shared/models';
import { plainToClass } from 'class-transformer';
import { firstValueFrom } from 'rxjs';

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    dealerGroups: [],
    employeeCertifications: [],
    dealerCertifications: [],
    dealers: [],
    dealer: undefined,
    employee: undefined,
    isLoading: [],
    pageLoading: true,
  },
})
@Injectable()
export class DashboardState {
  constructor(private apiService: ApiService) {}

  @Selector()
  static productGroupsWithCertifications(state: DashboardStateModel): DealerGroup[] {
    return state.dealerGroups;
  }

  @Selector()
  static employeesWithLearningPlans(state: DashboardStateModel): Employee[] {
    return state.employeeCertifications;
  }

  @Selector()
  static dealerCertifications(state: DashboardStateModel): Certification[] {
    return state.dealerCertifications;
  }

  @Selector()
  static employeeWithLearningPlans(state: DashboardStateModel): Employee | undefined {
    return state.employee;
  }

  @Selector()
  static dealers(state: DashboardStateModel): Dealer[] | undefined {
    return state.dealers;
  }

  @Selector()
  static dealer(state: DashboardStateModel): Dealer | undefined {
    return state.dealer;
  }

  @Selector()
  static isLoading(loadingData: LoadingData | LoadingData[]): (state: { dashboard: DashboardStateModel }) => boolean {
    return createSelector([DashboardState], (state: { dashboard: DashboardStateModel }): boolean => {
      if (state.dashboard.pageLoading) {
        return true;
      }
      if (!Array.isArray(loadingData)) {
        loadingData = [loadingData];
      }
      return loadingData.some((l: LoadingData) => state.dashboard.isLoading.includes(l));
    });
  }

  @Action(SetPageLoadingFinished)
  public async setPageLoadingFinished(ctx: StateContext<DashboardStateModel>): Promise<void> {
    ctx.patchState({ pageLoading: false });
  }

  @Action(LoadDealerGroupsWithCertifications)
  public async loadDealerGroupsWithCertifications(
    ctx: StateContext<DashboardStateModel>,
    { filterModel }: LoadDealerGroupsWithCertifications,
  ): Promise<void> {
    ctx.setState(
      patch({
        isLoading: insertItem(LoadingData.generalData),
      }),
    );

    const dealerGroups: DealerGroup[] = await firstValueFrom(
      this.apiService.productGroupsWithCertifications(filterModel),
    );

    const dealerCertifications: Certification[] = [];

    for (const dealerGroup of dealerGroups) {
      for (const certificationGroup of dealerGroup.certificationGroups ?? []) {
        for (const certification of certificationGroup.certifications ?? []) {
          if ((certification.enrolledEmployees ?? 0) > 0) {
            dealerCertifications.push(certification);
          }
        }
      }
    }
    ctx.setState(
      patch({
        isLoading: removeItem(
          ctx.getState().isLoading.findIndex((l: LoadingData): boolean => l == LoadingData.generalData),
        ),
        dealerGroups,
        dealerCertifications,
      }),
    );
  }

  @Action(LoadDealerWithCertifications)
  public async loadDealerWithCertifications(
    ctx: StateContext<DashboardStateModel>,
    { dealerId, filterModel }: LoadDealerWithCertifications,
  ): Promise<void> {
    ctx.setState(
      patch({
        isLoading: insertItem(LoadingData.dealerData),
      }),
    );
    const dealer: Dealer = await firstValueFrom(
      this.apiService.dealerWithCertificationData(
        dealerId,
        filterModel.dealerGroupIds,
        filterModel.dealerProductGroupIds,
        filterModel.certificationIds,
      ),
    );

    const certificationNames = (
      dealer.certificationDealerData
        ?.map((c) => c.certifications.map((e) => e.shortName))
        .reduce((acc, val) => acc.concat(val), []) ?? []
    ).filter((value, index, array) => array.indexOf(value) === index);

    const uniqueCertificationGroupsNames = certificationNames
      .map((c) => c.replace('II', '').replace('I', '').trim())
      .filter((value, index, array) => array.indexOf(value) === index)
      .sort((a, b) => b.localeCompare(a));

    const hasLvlTwo = certificationNames.find((c) => c.endsWith(' II')) !== undefined;

    const dealerCertificationData: DealerCertificationData[] = [];
    for (const uniqueCertificationGroupsName of uniqueCertificationGroupsNames) {
      const dataOne = (dealer.certificationDealerData ?? []).find(
        (c) =>
          (c.certifications.filter((f) => f.shortName.startsWith(uniqueCertificationGroupsName) && f.level == 1) ?? [])
            .length > 0,
      );

      const data = new DealerCertificationData();
      data.certificationGroupName = uniqueCertificationGroupsName;

      data.enrolledEmployees = dataOne!.totalEnrolledEmployees;
      data.certififiedLevelOne = dataOne!.certifiedEmployees;
      if (hasLvlTwo) {
        const dataTwo = (dealer.certificationDealerData ?? []).find(
          (c) =>
            (
              c.certifications.filter((f) => f.shortName.startsWith(uniqueCertificationGroupsName) && f.level == 2) ??
              []
            ).length > 0,
        );
        if (!!dataTwo) {
          data.certififiedLevelTwo = dataTwo!.certifiedEmployees;
        } else {
          data.certififiedLevelTwo = 0;
        }
      }

      dealerCertificationData.push(data);
    }
    dealer.dealerCertificationData = dealerCertificationData;

    ctx.setState(
      patch({
        isLoading: removeItem(
          ctx.getState().isLoading.findIndex((l: LoadingData): boolean => l == LoadingData.dealerData),
        ),
        dealer,
      }),
    );
  }

  @Action(LoadEmployeesWithCertifications)
  public async loadEmployeesWithCertifications(
    ctx: StateContext<DashboardStateModel>,
    { dealerId, filterModel }: LoadEmployeesWithCertifications,
  ): Promise<void> {
    ctx.setState(
      patch({
        isLoading: insertItem(LoadingData.employeesData),
      }),
    );
    const employeeCertifications: Employee[] = await firstValueFrom(
      this.apiService.employeesWithCertifications(
        dealerId,
        filterModel.dealerGroupIds,
        filterModel.dealerProductGroupIds,
        filterModel.certificationIds,
      ),
    );
    const data: Employee[] = [];
    for (const employee of employeeCertifications) {
      data.push(
        ...(employee.learningPlans ?? [])
          .filter((l: EmployeeLearningPlan) => !!l.learningPlan?.certification)
          .map(
            (learningPlan: EmployeeLearningPlan): Employee =>
              plainToClass(Employee, {
                ...employee,
                learningPlans: [learningPlan],
              }),
          ),
      );
    }

    ctx.setState(
      patch({
        isLoading: removeItem(
          ctx.getState().isLoading.findIndex((l: LoadingData): boolean => l == LoadingData.employeesData),
        ),
        employeeCertifications: data,
      }),
    );
  }

  @Action(LoadEmployeeWithCertifications)
  public async loadEmployeeWithCertifications(
    ctx: StateContext<DashboardStateModel>,
    { employeeEntityId }: LoadEmployeeWithCertifications,
  ): Promise<void> {
    ctx.setState(
      patch({
        isLoading: insertItem(LoadingData.employeeData),
      }),
    );

    const employee: Employee = await firstValueFrom(this.apiService.employeeWithCertifications(employeeEntityId));
    const employeeCourses: EmployeeCourse[] = [];
    for (const employeeLearningPlan of employee.learningPlans!) {
      employeeCourses.push(
        ...employeeLearningPlan.employeeCourses!.map((e) => {
          return plainToClass(EmployeeCourse, {
            ...e,
            course: {
              ...e.course,
              learningPlan: employeeLearningPlan.learningPlan,
            },
          });
        }),
      );
    }

    ctx.setState(
      patch({
        isLoading: removeItem(
          ctx.getState().isLoading.findIndex((l: LoadingData): boolean => l == LoadingData.employeeData),
        ),
        employee: plainToClass(Employee, { ...employee, courses: employeeCourses }),
      }),
    );
  }

  @Action(LoadDealersCertificationData)
  public async loadDealerCertificationData(
    ctx: StateContext<DashboardStateModel>,
    { entityIds, filterModel }: LoadDealersCertificationData,
  ): Promise<void> {
    ctx.setState(
      patch({
        isLoading: insertItem(LoadingData.dealerData),
      }),
    );
    const dealers: Dealer[] = await firstValueFrom(
      this.apiService.dealersWithCertificationData(
        entityIds,
        filterModel.dealerGroupIds!,
        filterModel.dealerProductGroupIds,
        filterModel.certificationIds,
      ),
    );
    ctx.setState(
      patch({
        isLoading: removeItem(
          ctx.getState().isLoading.findIndex((l: LoadingData): boolean => l == LoadingData.dealerData),
        ),
        dealers,
      }),
    );
  }
}
