import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { AddFilterData, FiltersState } from '@core/states/filters';
import { LoadRegion, LoadRegions, LoadRegionsByCountries, RegionsStateModel } from '@core/states/regions';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FilterType } from '@shared/enums';
import { Region } from '@shared/models';
import { Observable, tap } from 'rxjs';

@State<RegionsStateModel>({
  name: 'regions',
  defaults: {
    region: null,
    regions: [],
  },
})
@Injectable()
export class RegionsState {
  constructor(
    private apiService: ApiService,
    private store: Store,
  ) {}

  @Selector()
  static regions(state: RegionsStateModel): Region[] {
    return state.regions;
  }

  @Action(LoadRegions)
  public loadRegions(ctx: StateContext<RegionsStateModel>): Observable<Region[]> {
    return this.apiService.regions().pipe(
      tap((regions: Region[]): void => {
        ctx.patchState({ regions });
      }),
    );
  }

  @Action(LoadRegionsByCountries)
  public loadRegionsByCountries(
    ctx: StateContext<RegionsStateModel>,
    { countryIds }: LoadRegionsByCountries,
  ): Observable<Region[]> {
    countryIds ??= this.store.selectSnapshot(FiltersState.activeFiltersByType(FilterType.Country)).map((f) => f.id);

    return this.apiService.regionsByCountries(countryIds).pipe(
      tap((regions: Region[]): void => {
        ctx.patchState({ regions });
        this.store.dispatch(new AddFilterData(FilterType.Region, regions));
      }),
    );
  }

  @Action(LoadRegion)
  public loadRegion(ctx: StateContext<RegionsStateModel>, { id }: LoadRegion): Observable<Region> {
    return this.apiService.region(id).pipe(
      tap((region: Region): void => {
        ctx.patchState({ region });
      }),
    );
  }
}
