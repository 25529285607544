import { LoadingData } from '@shared/enums';
import { FilterInputModel } from '@shared/models';

export class LoadDealerGroupsWithCertifications {
  public static readonly type = '[Dashboard] Load dealerGroups with certifications';

  public constructor(public filterModel?: FilterInputModel) {}
}

export class LoadDealersCertificationData {
  public static readonly type = '[Dashboard] Load dealers data with certifications';

  public constructor(
    public entityIds: string[],
    public filterModel: FilterInputModel,
  ) {}
}

export class LoadDealerWithCertifications {
  public static readonly type = '[Dashboard] Load dealer with certifications';

  public constructor(
    public dealerId: string,
    public filterModel: FilterInputModel,
  ) {}
}

export class LoadEmployeesWithCertifications {
  public static readonly type = '[Dashboard] Load employees with certifications';

  public constructor(
    public dealerId: string,
    public filterModel: FilterInputModel,
  ) {}
}

export class LoadEmployeeWithCertifications {
  public static readonly type = '[Dashboard] Load employee with certifications';

  public constructor(public employeeEntityId: string) {}
}

export class LoadDealerCertificationData {
  public static readonly type = '[Dashboard] Load dealer data with certifications';

  public constructor(public filterModel: FilterInputModel) {}
}

export class AddLoading {
  public static readonly type = '[Dashboard] Set loading data';

  public constructor(public type: LoadingData) {}
}

export class RemoveLoading {
  public static readonly type = '[Dashboard] Remove loading data';

  public constructor(public type: LoadingData) {}
}

export class SetPageLoadingFinished {
  public static readonly type = '[Dashboard] Set page loading finished';

  public constructor() {}
}
