import { FilterSelectionType, FilterType } from '@shared/enums';
import { IFilterable, IFilterSettings } from '@shared/interfaces';
import { Type } from 'class-transformer';

import { ExpiryType } from '../enums';
import { LearningPlan } from './learning-plan.model';

export class Course implements IFilterable {
  @Type(() => LearningPlan)
  public learningPlan?: LearningPlan;
  public id!: string;
  public name!: string;
  public expiryTime!: number;
  public expiryTimeType!: ExpiryType;
  public softDeadline!: boolean;
  public selected = false;
  public filterSettings: IFilterSettings = {
    type: FilterType.Course,
    selectionType: FilterSelectionType.Single,
  };
}
