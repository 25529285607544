export enum FilterType {
  Default = 'Default',
  DealerGroup = 'Product Group',
  DealerProductGroup = 'Product',
  Certification = 'Certification',
  Country = 'Country',
  Course = 'Course',
  Region = 'Region',
  DealerType = 'Dealer type',
  Dealer = 'Dealer',
  Employee = 'Employee',
}
