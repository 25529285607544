import { formatPercent } from '@angular/common';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { FiltersState } from '@core/states';
import { AddFilters, DashboardState } from '@core/states';
import { areaTree } from '@misc/filter-node';
import { Store } from '@ngxs/store';
import { Select } from '@ngxs/store';
import { ContentAlignment, TableData, TableFilter, TableFilterOption, TableHeader } from '@shared/classes';
import { FilterType, LoadingData } from '@shared/enums';
import { Certification, Dealer, DealerCertificationData, Employee, Filter, FilterInputModel } from '@shared/models';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-dealer-data',
  templateUrl: './dealer-data.component.html',
  styleUrls: ['./dealer-data.component.scss'],
})
export class DealerDataComponent {
  @Select(FiltersState.activeFiltersArray)
  public activeFilters$!: Observable<Filter[]>;

  @Select(FiltersState.filterModel)
  public filterModel$!: Observable<FilterInputModel>;

  @Select(DashboardState.dealerCertifications)
  public dealerCertifications$!: Observable<Certification[]>;

  @Select(DashboardState.dealer)
  public dealer$!: Observable<Dealer>;

  @Select(DashboardState.employeesWithLearningPlans)
  public employeesWithCertification$!: Observable<Employee[]>;

  @Select(DashboardState.isLoading(LoadingData.dealerData))
  public isLoading$!: Observable<boolean>;

  public dealerCertificationData: DealerCertificationData[] = [];

  public dealersCertificationStatusTable: TableData<DealerCertificationData> = new TableData<DealerCertificationData>([
    new TableHeader('Certification', (item: DealerCertificationData): string => `${item.certificationGroupName}`, [], {
      align: ContentAlignment.START,
      preserveBreaks: true,
    }),
    new TableHeader('Enrolled employees', (item: DealerCertificationData): number => item.enrolledEmployees ?? 0, []),

    new TableHeader('Certification level 1', (): string => '', [
      new TableHeader('Employees', (item: DealerCertificationData): number => item.certififiedLevelOne, []),
      new TableHeader(
        'Percentage',
        (item: DealerCertificationData): number => (item.certififiedLevelOne / item.enrolledEmployees) * 100,
        [],
        {
          aliceTheDisplayValueBuilder: (item): string =>
            formatPercent((item.certififiedLevelOne ?? 0) / item.enrolledEmployees, this.locale, '1.0-2'),
          sortKey: 'percentage_lvl_1',
        },
      ),
    ]),
    new TableHeader('Certification level 2', (): string => '', [
      new TableHeader('Employees', (item: DealerCertificationData): number => item.certififiedLevelTwo ?? 0, []),
      new TableHeader(
        'Percentage lvl 2',
        (item: DealerCertificationData): number => ((item.certififiedLevelTwo ?? 0) / item.enrolledEmployees) * 100,
        [],
        {
          aliceTheDisplayValueBuilder: (item): string =>
            formatPercent((item.certififiedLevelTwo ?? 0) / item.enrolledEmployees, this.locale, '1.0-2'),
          sortKey: 'percentage_lvl_2',
        },
      ),
    ]),
  ]);

  public tableFilters: TableFilter<Employee>[] = [
    new TableFilter<Employee>('completion', [
      new TableFilterOption('All', (): boolean => true),
      new TableFilterOption('Completed', (e: Employee): boolean => e.learningPlans![0]!.completionPercentage === 100),
      new TableFilterOption(
        'Not completed',
        (e: Employee): boolean => e.learningPlans![0]!.completionPercentage != 100,
      ),
    ]),
  ];

  public employeeCertificationTable: TableData<Employee> = new TableData<Employee>([
    new TableHeader('Employee', (item: Employee): string => item.name, [], {
      align: ContentAlignment.START,
    }),
    new TableHeader('Username', (item: Employee): string => item.username, [], {
      align: ContentAlignment.START,
    }),
    new TableHeader(
      'Certification',
      (item: Employee): string => item.learningPlans![0]!.learningPlan!.certification?.name ?? '',
    ),
    new TableHeader('Completion', (item: Employee): number => item.learningPlans![0]!.completionPercentage! / 100, [], {
      aliceTheDisplayValueBuilder: (_: Employee, value: string | number | null): string =>
        formatPercent(value as number, this.locale, '1.0-2'),
      textColorCallback: (item: Employee): string =>
        item.learningPlans![0]!.completionPercentage! == 100 ? 'green' : 'red',
    }),
  ]);

  public get hasSelectedDealer(): Observable<boolean> {
    return this.activeFilters$.pipe(
      map((filters: Filter[]): boolean => areaTree.findBranchEnding(filters) === FilterType.Dealer),
    );
  }

  public onEmployeeRowClicked = (employee: Employee): void => {
    this.store.dispatch(new AddFilters([employee]));
  };

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public store: Store,
  ) {
    this.dealer$.subscribe((dealer: Dealer) => {
      if (!!dealer && !!dealer.dealerCertificationData) {
        this.dealerCertificationData = dealer.dealerCertificationData.filter((d) => d.enrolledEmployees > 0);
      }
    });
  }
}
