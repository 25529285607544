import { Component, OnInit } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Authenticate } from '@src/auth/states/auth';
import { catchError, EMPTY, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss'],
})
export class AuthenticateComponent implements OnInit {
  public isBusy = true;
  public error?: { title: string; message?: string };
  public constructor(public readonly store: Store) {}

  public async ngOnInit(): Promise<void> {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('user_id')!;
    const username = queryParams.get('username');
    const authCode = queryParams.get('auth_code');
    const hash = queryParams.get('hash');

    if (!userId || !username || !authCode || !hash) {
      const token = localStorage.getItem('token');
      if (!token) {
        this.isBusy = false;
        this.error = {
          title: 'Something went wrong while authenticating, please reload the page',
        };
        this.store.dispatch(new Navigate(['/login']));
      } else {
        this.store.dispatch(new Navigate(['/dashboard']));
      }

      return;
    }

    this.store
      .dispatch(new Authenticate(userId!, username!, authCode!, hash!))
      .pipe(
        catchError((e) => {
          this.error = {
            title: 'Something went wrong while authenticating, please reload the page',
            message: `${e}`,
          };
          this.isBusy = false;
          return EMPTY;
        }),
        switchMap(() => this.store.dispatch(new Navigate(['/dashboard']))),
        tap(() => (this.isBusy = false)),
      )
      .subscribe();
  }
}
