import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  Certification,
  Country,
  Course,
  Dealer,
  DealerGroup,
  DealerProductGroup,
  DealerType,
  Employee,
  Entity,
  FilterInputModel,
  LearningPlan,
  Region,
} from '@shared/models';
import { plainToInstance } from 'class-transformer';
import { gql, GraphQLClient } from 'graphql-request';
import { filter, from, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public graphQlClient: GraphQLClient;

  constructor(private store: Store) {
    this.graphQlClient = new GraphQLClient(environment.apiUrl);
  }

  public async setApiKeyHeader(token: string): Promise<void> {
    // token valid
    this.graphQlClient.setHeader('Authorization', `Bearer ${token}`);
  }

  public async makeRequest(document: any, data?: any, useToken: boolean = true): Promise<any> {
    try {
      if (useToken) {
        const token = localStorage.getItem('token');
        if (!!token) {
          await this.setApiKeyHeader(token);
        }
      }
      return await this.graphQlClient.request(document, data);
    } catch (error: any) {
      throw error;
    }
  }

  public dealerGroups(): Observable<DealerGroup[]> {
    const document: string = gql`
      query {
        dealergroups {
          id
          name: code
        }
      }
    `;
    return from(this.makeRequest(document)).pipe(
      filter((response): boolean => response.dealergroups != null),
      map((response: any) =>
        response.dealergroups.map((dealerGroup: DealerGroup) => plainToInstance(DealerGroup, dealerGroup)),
      ),
    );
  }

  public dealerProductGroups(dealerGroupIds?: string[]): Observable<DealerProductGroup[]> {
    const document = gql`
      query ($dealerGroupIds: [String!]) {
        dealerproductgroups(dealerGroupIds: $dealerGroupIds) {
          id
          name: code
        }
      }
    `;

    return from(this.makeRequest(document, { dealerGroupIds })).pipe(
      filter((response): boolean => response.dealerproductgroups != null),
      map((response: any) =>
        response.dealerproductgroups.map((dealerProductGroup: DealerProductGroup) =>
          plainToInstance(DealerProductGroup, dealerProductGroup),
        ),
      ),
    );
  }

  public countries(): Observable<Country[]> {
    const document: string = gql`
      query {
        countries {
          id
          entityId
          name
        }
      }
    `;
    return from(this.makeRequest(document)).pipe(
      filter((response): boolean => response.countries != null),
      map((response: any) => response.countries.map((country: Country) => plainToInstance(Country, country))),
    );
  }

  public certifications(dealerGroupIds?: string[], dealerProductGroupIds?: string[]): Observable<Certification[]> {
    const document: string = gql`
      query ($dealerGroupIds: [String!], $dealerProductGroupIds: [String!]) {
        certificationsByDealerGroupIds(dealerGroupIds: $dealerGroupIds, dealerProductGroupIds: $dealerProductGroupIds) {
          id
          shortName
          name
          code
          level
          description
        }
      }
    `;
    return from(this.makeRequest(document, { dealerGroupIds, dealerProductGroupIds })).pipe(
      filter((response): boolean => response.certificationsByDealerGroupIds != null),
      map((response: any) =>
        response.certificationsByDealerGroupIds.map((certification: Certification) =>
          plainToInstance(Certification, certification),
        ),
      ),
    );
  }

  public regions(): Observable<Region[]> {
    const document: string = gql`
      query {
        regions {
          id
          entityId
          name
        }
      }
    `;
    return from(this.makeRequest(document)).pipe(
      filter((response): boolean => response.regions != null),
      map((response: any) => response.regions.map((region: Region) => plainToInstance(Region, region))),
    );
  }

  public regionsByCountries(countryIds: string[]): Observable<Region[]> {
    const document: string = gql`
      query ($countryIds: [String!]!) {
        regionsByCountries(countryIds: $countryIds) {
          id
          entityId
          name
        }
      }
    `;
    return from(this.makeRequest(document, { countryIds })).pipe(
      filter((response): boolean => response.regionsByCountries != null),
      map((response: any) => response.regionsByCountries.map((region: Region) => plainToInstance(Region, region))),
    );
  }

  public region(regionId: string): Observable<Region> {
    const document: string = gql`
      query ($regionId: String!) {
        region(id: $regionId) {
          id
          entityId
          name
        }
      }
    `;
    return from(this.makeRequest(document, { regionId })).pipe(
      map((response): Region => response.region as Region),
      map((response: Region) => {
        if (response.entity?.dealer == null) {
          // response.entity?.dealer = [];
        }
        return response;
      }),
      map((response: Region): Region => plainToInstance(Region, response)),
    );
  }

  public dealers(): Observable<Dealer[]> {
    const document: string = gql`
      query {
        dealers {
          id
          entityId
          code
          name
          dealerType
          filterName
        }
      }
    `;
    return from(this.makeRequest(document)).pipe(
      filter((response): boolean => response.dealers != null),
      map((response: any) => response.dealers.map((dealer: Dealer) => plainToInstance(Dealer, dealer))),
    );
  }

  public dealerTypes(
    entityIds?: string[],
    dealerGroupIds?: string[],
    dealerProductGroupIds?: string[],
  ): Observable<DealerType[]> {
    const document: string = gql`
      query ($entityIds: [String!], $dealerGroupIds: [String!], $dealerProductGroupIds: [String!]) {
        dealerTypes(
          entityIds: $entityIds
          dealerGroupIds: $dealerGroupIds
          dealerProductGroupIds: $dealerProductGroupIds
        )
      }
    `;
    return from(this.makeRequest(document, { entityIds, dealerGroupIds, dealerProductGroupIds })).pipe(
      map((response: any) => response.dealerTypes.map((d: string) => plainToInstance(DealerType, { id: d, name: d }))),
    );
  }

  public dealersBy(
    entityIds?: string[],
    dealerGroupIds?: string[],
    dealerProductGroupIds?: string[],
    dealerTypes?: string[],
  ): Observable<Dealer[]> {
    const document: string = gql`
      query (
        $entityIds: [String]
        $dealerGroupIds: [String]
        $dealerProductGroupIds: [String]
        $dealerTypes: [String]
      ) {
        dealersBy(
          entityIds: $entityIds
          dealerGroupIds: $dealerGroupIds
          dealerProductGroupIds: $dealerProductGroupIds
          dealerTypes: $dealerTypes
        ) {
          id
          entityId
          code
          name
          dealerType
          filterName
        }
      }
    `;
    return from(this.makeRequest(document, { entityIds, dealerGroupIds, dealerProductGroupIds, dealerTypes })).pipe(
      filter((response): boolean => response.dealersBy != null),
      map((response: any) => response.dealersBy.map((dealer: Dealer[]) => plainToInstance(Dealer, dealer))),
    );
  }

  public dealersWithCertificationData(
    entityIds: string[],
    dealerGroupIds: string[],
    dealerProductGroupIds?: string[],
    certificationIds?: string[],
    dealerTypes?: string[],
  ): Observable<Dealer[]> {
    const document: string = gql`
      query (
        $entityIds: [String]
        $dealerGroupIds: [String]
        $dealerProductGroupIds: [String]
        $certificationIds: [String!]
        $dealerTypes: [String!]
      ) {
        dealersBy(
          entityIds: $entityIds
          dealerGroupIds: $dealerGroupIds
          dealerProductGroupIds: $dealerProductGroupIds
          dealerTypes: $dealerTypes
        ) {
          id
          entityId
          code
          name
          dealerType
          filterName
          region {
            name
          }
          totalEmployees
          certificationDealerData(
            dealerGroupIds: $dealerGroupIds
            dealerProductGroupIds: $dealerProductGroupIds
            certificationIds: $certificationIds
            dealerTypes: $dealerTypes
          ) {
            certificationGroup {
              name
            }
            certifications {
              shortName
              level
              dealerGroup {
                name: code
              }
            }
            certifiedEmployees
            totalEnrolledEmployees
          }
        }
      }
    `;
    return from(
      this.makeRequest(document, { entityIds, dealerGroupIds, dealerProductGroupIds, certificationIds, dealerTypes }),
    ).pipe(
      filter((response): boolean => response.dealersBy != null),
      map((response: any) => response.dealersBy.map((dealer: Dealer[]) => plainToInstance(Dealer, dealer))),
    );
  }

  public employees(): Observable<Employee[]> {
    const document: string = gql`
      query {
        employees {
          id
          entityId
          username
          firstName
          lastName
          name: fullName
        }
      }
    `;
    return from(this.makeRequest(document)).pipe(
      filter((response): boolean => response.employees != null),
      map((response: any) => response.employees.map((employee: Employee) => plainToInstance(Employee, employee))),
    );
  }

  public employeesByDealer(dealerId: string): Observable<Employee[]> {
    const document: string = gql`
      query ($dealerId: String!) {
        employeesByDealer(dealerId: $dealerId) {
          id
          entityId
          username
          firstName
          lastName
          name: fullName
        }
      }
    `;
    return from(this.makeRequest(document, { dealerId })).pipe(
      filter((response): boolean => response.employeesByDealer != null),
      map((response: any) =>
        response.employeesByDealer.map((employee: Employee) => plainToInstance(Employee, employee)),
      ),
    );
  }

  public courses(): Observable<Course[]> {
    const document: string = gql`
      query {
        courses {
          id
          name
        }
      }
    `;
    return from(this.makeRequest(document)).pipe(
      filter((response): boolean => response.courses != null),
      map((response: any) => response.courses.map((course: Course) => plainToInstance(Course, course))),
    );
  }

  public entities(): Observable<Entity[]> {
    const document: string = gql`
      query {
        entities {
          id
          type
          children {
            id
            type
          }
        }
      }
    `;
    return from(this.makeRequest(document)).pipe(
      filter((response): boolean => response.entities != null),
      map((response: any) => response.entities.map((entity: Entity) => plainToInstance(Entity, entity))),
    );
  }

  public learningPlans(): Observable<LearningPlan[]> {
    const document: string = gql`
      query {
        learningPlans {
          id
          name
          code
        }
      }
    `;
    return from(this.makeRequest(document)).pipe(
      filter((response): boolean => response.learningPlans != null),
      map((response: any) =>
        response.learningPlans.map((learningPlan: LearningPlan) => plainToInstance(LearningPlan, learningPlan)),
      ),
    );
  }

  public productGroupsWithCertifications(filterModel?: FilterInputModel): Observable<DealerGroup[]> {
    const document = gql`
      query (
        $entityIds: [String!]
        $dealerGroupIds: [String!]
        $dealerProductGroupIds: [String!]
        $certificationIds: [String!]
        $dealerTypes: [String]
      ) {
        dealergroups(dealerGroupIds: $dealerGroupIds, dealerProductGroupIds: $dealerProductGroupIds) {
          id
          name: code
          createdAt
          totalDealers(entityIds: $entityIds, dealerProductGroupIds: $dealerProductGroupIds, dealerTypes: $dealerTypes)
          certificationGroups(certificationIds: $certificationIds) {
            id
            name
            certifiedDealers(
              entityIds: $entityIds
              dealerGroupIds: $dealerGroupIds
              dealerProductGroupIds: $dealerProductGroupIds
              certificationIds: $certificationIds
              dealerTypes: $dealerTypes
            ) {
              atLeastOneEnrolled
              allCertifiedLevelOne
              atLeastOneCertifiedLevelOne
              atLeastOneCertifiedLevelTwo
            }
            certifications(
              certificationIds: $certificationIds
              dealerGroupIds: $dealerGroupIds
              dealerProductGroupIds: $dealerProductGroupIds
            ) {
              id
              shortName
              name
              level
              code
              enrolledEmployees(
                entityIds: $entityIds
                dealerGroupIds: $dealerGroupIds
                dealerProductGroupIds: $dealerProductGroupIds
                dealerTypes: $dealerTypes
              )
              enrolled0to20: enrolledEmployees(
                entityIds: $entityIds
                dealerGroupIds: $dealerGroupIds
                dealerProductGroupIds: $dealerProductGroupIds
                dealerTypes: $dealerTypes
                maxPercentage: 20
              )
              enrolled20to40: enrolledEmployees(
                entityIds: $entityIds
                dealerGroupIds: $dealerGroupIds
                dealerProductGroupIds: $dealerProductGroupIds
                dealerTypes: $dealerTypes
                minPercentage: 20
                maxPercentage: 40
              )
              enrolled40to60: enrolledEmployees(
                entityIds: $entityIds
                dealerGroupIds: $dealerGroupIds
                dealerProductGroupIds: $dealerProductGroupIds
                dealerTypes: $dealerTypes
                minPercentage: 40
                maxPercentage: 60
              )
              enrolled60to80: enrolledEmployees(
                entityIds: $entityIds
                dealerGroupIds: $dealerGroupIds
                dealerProductGroupIds: $dealerProductGroupIds
                dealerTypes: $dealerTypes
                minPercentage: 60
                maxPercentage: 80
              )
              enrolled80to100: enrolledEmployees(
                entityIds: $entityIds
                dealerGroupIds: $dealerGroupIds
                dealerProductGroupIds: $dealerProductGroupIds
                dealerTypes: $dealerTypes
                minPercentage: 80
                maxPercentage: 100
              )
              enrolled100: enrolledEmployees(
                entityIds: $entityIds
                dealerGroupIds: $dealerGroupIds
                dealerProductGroupIds: $dealerProductGroupIds
                dealerTypes: $dealerTypes
                minPercentage: 100
              )
            }
          }
        }
      }
    `;
    let data;
    if (filterModel !== undefined) {
      data = {
        entityIds: filterModel.entityIds,
        dealerGroupIds: filterModel.dealerGroupIds,
        dealerProductGroupIds: filterModel.dealerProductGroupIds,
        certificationIds: filterModel.certificationIds,
        dealerTypes: filterModel.dealerTypes,
      };
    }

    return from(this.makeRequest(document, data)).pipe(
      map((response: { dealergroups: DealerGroup[] }) =>
        response.dealergroups.map((dealergroup: DealerGroup) => plainToInstance(DealerGroup, dealergroup)),
      ),
    );
  }

  public dealerWithCertificationData(
    entityId: string,
    dealerGroupIds?: string[],
    dealerProductGroupIds?: string[],
    certificationIds?: string[],
    dealerTypes?: string[],
  ): Observable<Dealer> {
    const document: string = gql`
      query (
        $entityId: String!
        $dealerGroupIds: [String]
        $dealerProductGroupIds: [String]
        $certificationIds: [String]
        $dealerTypes: [String]
      ) {
        dealerByEntityId(entityId: $entityId) {
          id
          entityId
          code
          name
          dealerType
          filterName
          region {
            name
          }
          totalEmployees
          certificationDealerData(
            dealerGroupIds: $dealerGroupIds
            dealerProductGroupIds: $dealerProductGroupIds
            certificationIds: $certificationIds
            dealerTypes: $dealerTypes
          ) {
            certificationGroup {
              name
            }
            certifications {
              shortName
              level
            }
            certifiedEmployees
            totalEnrolledEmployees
          }
        }
      }
    `;

    return from(
      this.makeRequest(document, { entityId, dealerGroupIds, dealerProductGroupIds, certificationIds, dealerTypes }),
    ).pipe(map((response: any) => plainToInstance(Dealer, response.dealerByEntityId)));
  }

  public employeesWithCertifications(
    dealerId: string,
    dealerGroupIds?: string[],
    dealerProductGroupIds?: string[],
    certificationIds?: string[],
  ): Observable<Employee[]> {
    const document: string = gql`
      query (
        $dealerId: String!
        $dealerGroupIds: [String]
        $dealerProductGroupIds: [String]
        $certificationIds: [String]
      ) {
        employeesByDealerEntityId(dealerId: $dealerId) {
          id
          username
          firstName
          lastName
          name: fullName
          learningPlans(
            certificationIds: $certificationIds
            dealerGroupIds: $dealerGroupIds
            dealerProductGroupIds: $dealerProductGroupIds
          ) {
            learningPlan {
              name
              level
              certification {
                name
                level
              }
            }
            completionPercentage
            completedAt
          }
          certifications(certificationIds: $certificationIds) {
            id
            completedAt
            certification {
              id
              name
              code
              description
              expiration
            }
          }
        }
      }
    `;

    return from(this.makeRequest(document, { dealerId, dealerGroupIds, dealerProductGroupIds, certificationIds })).pipe(
      map((response: any) =>
        response.employeesByDealerEntityId.map((employee: Employee) => plainToInstance(Employee, employee)),
      ),
    );
  }

  public employeeWithCertifications(entityId: string): Observable<Employee> {
    const document: string = gql`
      query ($entityId: String!) {
        employeeByEntityId(entityId: $entityId) {
          id
          username
          firstName
          lastName
          name: fullName
          lastLoginDate
          registrationDate
          roles {
            name
          }
          productGroups {
            name: code
          }
          learningPlans {
            learningPlan {
              name
              level
            }
            completedAt
            completionPercentage
            employeeCourses {
              course {
                id
                name
                expiryTime
                expiryTimeType
                softDeadline
              }
              completedAt
              enrolledAt
              enrollmentStatus
            }
          }
          certifications {
            completedAt
            certification {
              name
              level
              description
              expiration
            }
          }
        }
      }
    `;

    return from(this.makeRequest(document, { entityId })).pipe(
      map((response: any) => plainToInstance(Employee, response.employeeByEntityId)),
    );
  }

  public exchangeAuthCode(userId: string, username: string, authCode: string, hash: string): Observable<string> {
    const document: string = gql`
      mutation ($username: String!, $userId: String!, $authCode: String!, $hash: String!) {
        exchangeAuthCode(username: $username, userId: $userId, authCode: $authCode, hash: $hash) {
          accessToken
        }
      }
    `;

    return from(this.makeRequest(document, { userId, username, authCode, hash }, false)).pipe(
      map((response) => response.exchangeAuthCode.accessToken),
    );
  }

  public login(username: string, password: string): Observable<string> {
    const document: string = gql`
      mutation ($username: String!, $password: String!) {
        login(username: $username, password: $password) {
          accessToken
          tokenType
        }
      }
    `;

    return from(this.makeRequest(document, { username, password }, false)).pipe(
      map((response) => response.login.accessToken),
    );
  }

  public refreshToken(jwtToken: string): Observable<string> {
    const document: string = gql`
      mutation ($jwtToken: String!) {
        refreshToken(jwtToken: $jwtToken) {
          accessToken
        }
      }
    `;

    return from(this.makeRequest(document, { jwtToken }, false)).pipe(
      map((response) => response.refreshToken.accessToken),
    );
  }
}
