<div class="content mt-5">
  <div class="loading-indicator">
    <div class="indicator">
      <div class="inner-indicator">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
