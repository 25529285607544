export class LoadRegion {
  public static readonly type = '[Regions] Load region';

  public constructor(public id: string) {}
}

export class LoadRegions {
  public static readonly type = '[Regions] Load regions';

  public constructor() {}
}

export class LoadRegionsByCountries {
  public static readonly type = '[Regions] Load regions by country';

  public constructor(public countryIds?: string[]) {}
}
