import { AfterContentChecked, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { AddFilters, DashboardState, LoadEmployeesByDealer } from '@core/states';
import { Select, Store } from '@ngxs/store';
import { ContentAlignment, TableData, TableFilter, TableFilterOption, TableHeader } from '@shared/classes';
import { LoadingData, StatusIndicator } from '@shared/enums';
import { Dealer, DealerCertificationData } from '@shared/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dealers-certification-status-block',
  templateUrl: './dealers-certification-status-block.component.html',
  styleUrls: ['./dealers-certification-status-block.component.scss'],
})
export class DealersCertificationStatusBlockComponent implements AfterContentChecked {
  @ViewChild('statusIndicator', { static: false, read: TemplateRef })
  public statusIndicator!: TemplateRef<any>;
  @ViewChild('certificationData', { static: false, read: TemplateRef })
  public certificationData!: TemplateRef<any>;

  @Select(DashboardState.isLoading(LoadingData.dealerData))
  public isLoading$!: Observable<boolean>;

  @Input()
  public dealers!: Dealer[];

  public dealersCertificationStatusTable!: TableData<Dealer>;

  public tableFilters: TableFilter<Dealer>[] = [
    new TableFilter<Dealer>('Certification status', [
      new TableFilterOption('Certification status', (): boolean => true),
      new TableFilterOption('Green', (item: Dealer): boolean => this.rowStatus(item) == StatusIndicator.green),
      new TableFilterOption('Orange', (item: Dealer): boolean => this.rowStatus(item) == StatusIndicator.orange),
      new TableFilterOption('Yellow', (item: Dealer): boolean => this.rowStatus(item) == StatusIndicator.yellow),
      new TableFilterOption('Red', (item: Dealer): boolean => this.rowStatus(item) == StatusIndicator.red),
    ]),
  ];

  constructor(private store: Store) {}

  ngAfterContentChecked(): void {
    const certificationNames = this.dealers
      .map(
        (d) =>
          d.certificationDealerData
            ?.map((c) => c.certifications.map((e) => e.shortName))
            .reduce((acc, val) => acc.concat(val), []) ?? [],
      )
      .reduce((acc, val) => acc.concat(val), [])
      .filter((value, index, array) => array.indexOf(value) === index);

    const uniqueCertificationGroupsNames = certificationNames
      .map((c) => c.replace('II', '').replace('I', '').trim())
      .filter((value, index, array) => array.indexOf(value) === index)
      .sort((a, b) => b.localeCompare(a));

    this.dealersCertificationStatusTable = new TableData<Dealer>([
      new TableHeader('Dealer', (item: Dealer): string => `${item.code}\n${item.name}`, [], {
        align: ContentAlignment.START,
        preserveBreaks: true,
      }),
      new TableHeader('Dealer Type', (item: Dealer): string => item.dealerType, [], {
        align: ContentAlignment.START,
      }),
      new TableHeader('Area/Region', (item: Dealer): string => item.region?.name ?? '', [], {
        align: ContentAlignment.START,
      }),
      new TableHeader('Status', (item: Dealer): StatusIndicator => this.rowStatus(item), [], {
        template: this.statusIndicator,
      }),

      ...uniqueCertificationGroupsNames.map((s: string) => {
        let hasLvlTwo = false;
        let header = `${s}\nNR / LVL 1`;
        if (certificationNames.includes(`${s} II`)) {
          header = `${s}\nNR / LVL 1 / LVL 2`;
          hasLvlTwo = true;
        }

        return new TableHeader<Dealer>(
          header,
          (item: Dealer): DealerCertificationData => {
            const result = new DealerCertificationData();

            const dataOne = (item.certificationDealerData ?? []).find(
              (c) => (c.certifications.filter((f) => f.shortName.startsWith(s) && f.level == 1) ?? []).length > 0,
            );

            result.enrolledEmployees = dataOne?.totalEnrolledEmployees ?? 0;
            result.textColorEnrolled = this.colStatus(result.enrolledEmployees, 0, 0, '', '');

            result.certififiedLevelOne = dataOne?.certifiedEmployees ?? 0;
            result.textColorLevelOne = this.colStatus(
              result.enrolledEmployees,
              result.certififiedLevelOne,
              1,
              dataOne?.certifications[0]?.dealerGroup?.name ?? '',
              dataOne?.certifications[0]?.shortName ?? '',
            );

            if (hasLvlTwo) {
              const dataTwo = (item.certificationDealerData ?? []).find(
                (c) => c.certifications.filter((f) => f.shortName.startsWith(s) && f.level == 2).length > 0,
              );

              result.certififiedLevelTwo = dataTwo?.certifiedEmployees ?? 0;
              result.textColorLevelTwo = this.colStatus(
                result.enrolledEmployees,
                dataTwo?.certifiedEmployees ?? 0,
                2,
                dataTwo?.certifications[0]?.dealerGroup?.name ?? '',
                dataTwo?.certifications[0]?.shortName ?? '',
              );
            }
            return result;
          },
          [],
          {
            sortable: false,
            headerIsMultiline: true,
            template: this.certificationData,
            excelExportValue: (_: Dealer, value: DealerCertificationData): string =>
              (hasLvlTwo
                ? [value.enrolledEmployees, value.certififiedLevelOne, value.certififiedLevelTwo]
                : [value.enrolledEmployees, value.certififiedLevelOne]
              ).join(' / '),
          },
        );
      }),
    ]);
  }

  /*
   * Per Certification 3 values are displayed ( NR / LVL I / LVL II )
   *
   * NR = all users enrolled in the lvl I Certification LP of that dealer
   * LVL I = all users with a lvl I Certification LP at 100% of that dealer
   * LVL II = all user with a lvl II Certification LP at 100% of that dealer
   *
   * The stoplight based on the rules below check if there any value of the 3 values is red (condition not met)
   * and display the following color for the stoplight.:
   * IF on any of the Certification Groups the NR is red, the stoplight should show RED
   * IF on any of the Certification Groups the LVL I is red, the stoplight should show ORANGE
   * IF on any of the Certification Groups the LVL II is red, the stoplight should show YELLOW
   * IF all values in in all Certification Groups are black, the stoplight should show GREEN
   *
   * PRODUCT GROUP   CERTIFICATION GROUP    NR      LVL I       LVL II
   * PTW             TECHNICIAN             NR > 0  NR = LVL I  LVL II > 0
   * PTW             SERVICE ADVISOR        NR > 0  NR = LVL I  LVL II > 0
   * PTW             SALES ADVISOR          NR > 0  NR = LVL I  LVL II > 0
   * PTW             MARKETING COORDINATOR  NR > 0  LVL I > 0
   *
   * MARINE          TECHNICIAN             NR > 0  NR = LVL I
   *
   * RVPP            TECHNICIAN             NR > 0  NR = LVL I
   *
   * SPV             TECHNICIAN             NR > 0  LVL I > 0
   */
  public rowStatus(item: Dealer): StatusIndicator {
    const lvlOneCertifications = (item.certificationDealerData ?? []).filter(
      (c) => (c.certifications.filter((f) => f.level == 1) ?? []).length > 0,
    );
    const lvlTwoCertifications = (item.certificationDealerData ?? []).filter(
      (c) => (c.certifications.filter((f) => f.level == 2) ?? []).length > 0,
    );

    const hasAllLvlOneEnrollements = lvlOneCertifications.every(
      (c) => this.colStatus(c.totalEnrolledEmployees, c.certifiedEmployees, 0, '', '') == StatusIndicator.black,
    );
    const hasAllLvlOneCertifications = lvlOneCertifications.every((c) =>
      c.certifications.every(
        (cert) =>
          this.colStatus(c.totalEnrolledEmployees, c.certifiedEmployees, 1, cert.dealerGroup!.name, cert.shortName) ==
          StatusIndicator.black,
      ),
    );
    const hasAtLeastOneLvlTwoCertification = lvlTwoCertifications.every((c) =>
      c.certifications.find(
        (cert) =>
          this.colStatus(c.totalEnrolledEmployees, c.certifiedEmployees, 2, cert.dealerGroup!.name, cert.shortName) ==
          StatusIndicator.black,
      ),
    );

    if (lvlOneCertifications.length == 0 || !hasAllLvlOneEnrollements) {
      return StatusIndicator.red;
    }
    if (!hasAllLvlOneCertifications) {
      return StatusIndicator.orange;
    }
    if (!hasAtLeastOneLvlTwoCertification) {
      return StatusIndicator.yellow;
    }
    return StatusIndicator.green;
  }

  public colStatus(
    enrolledEmployees: number,
    certifiedEmployees: number,
    level: number,
    dealerGroupName: string,
    certificationShortName: string,
  ): StatusIndicator {
    if (level == 0) {
      if (enrolledEmployees == 0) {
        return StatusIndicator.red;
      }
    } else if (level == 1) {
      if (dealerGroupName == 'SPV' && certifiedEmployees >= 1) {
        return StatusIndicator.black;
      } else if (
        dealerGroupName == 'PTW' &&
        certificationShortName.startsWith('Marketing Coordinator') &&
        certifiedEmployees >= 1
      ) {
        return StatusIndicator.black;
      } else if (enrolledEmployees > 0 && enrolledEmployees == certifiedEmployees) {
        return StatusIndicator.black;
      } else {
        return StatusIndicator.red;
      }
    } else if (level == 2) {
      if (certifiedEmployees > 0) {
        return StatusIndicator.black;
      } else {
        return StatusIndicator.red;
      }
    }
    return StatusIndicator.black;
  }

  public onDealerRowClicked = (dealer: Dealer): void => {
    this.store.dispatch([new AddFilters([dealer]), new LoadEmployeesByDealer(dealer.id)]);
  };
}
