import { Dealer } from '@shared/models/dealer.model';
import { Type } from 'class-transformer';

export class Entity {
  @Type(() => Dealer)
  dealer?: Dealer;

  @Type(() => Entity)
  public children?: Entity[];
  public id!: string;
  public type!: string;
}
