import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartDataset, ChartOptions, DefaultDataPoint, Plugin } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-doughnut-graph',
  templateUrl: './doughnut-graph.component.html',
  styleUrls: ['./doughnut-graph.component.scss'],
})
export class DoughnutGraphComponent implements OnChanges {
  @ViewChild('centerTooltip', { read: ElementRef }) centerTooltip?: ElementRef;
  @Input() public total?: number;
  @Input() public data?: number[];
  @Input() public labels!: string[];
  @Input() public valueColors!: string[];

  @Input() public title?: string;
  @Input() public subtitle?: string;
  @Input() public showCenterTooltip?: boolean;

  @ViewChild(BaseChartDirective) chart!: BaseChartDirective<'doughnut', DefaultDataPoint<'doughnut'>, string>;

  public dataSet!: ChartData<'doughnut', DefaultDataPoint<'doughnut'>, string>;

  public chartType: ChartConfiguration<'doughnut', Array<number>, string>['type'] = 'doughnut';

  public options: ChartOptions<'doughnut'> = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    cutout: '65%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: (context: any) => {
          const { chart, tooltip } = context;
          const tooltipEl = chart.canvas.parentNode.querySelector('div.tooltip');

          const rawValue = tooltip.dataPoints[0]?.raw ?? 0;
          const titleEl = tooltipEl.querySelector('.title');
          titleEl.innerHTML = tooltip.title;

          const valueEl = tooltipEl.querySelector('.value');
          valueEl.innerHTML = `${rawValue} employees`;

          const percentage = Math.round((rawValue / (this.total ?? 1)) * 100);
          const percentageEl = tooltipEl.querySelector('.percentage');
          percentageEl.innerHTML = `${percentage}%`;

          this.centerTooltip!.nativeElement.style.display = 'none';
          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = positionX + tooltip.caretX + 'px';
          tooltipEl.style.top = positionY + tooltip.caretY + 'px';
          tooltipEl.style.font = tooltip.options.bodyFont.string;
          tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        },
      },
    },
    onHover: (event: any, elements: any[], chart: Chart): void => {
      if (this.showCenterTooltip) {
        // show center-tooltip when hovering over the center of the chart
        const centerX = chart.width / 2;
        const centerY = chart.height / 2;
        const offset = 30;

        if (
          event.x >= centerX - offset &&
          event.x <= centerX + offset &&
          event.y >= centerY - offset &&
          event.y <= centerY + offset
        ) {
          this.centerTooltip!.nativeElement.style.left = event.native.pageX + 'px';
          this.centerTooltip!.nativeElement.style.top = event.native.pageY - 45 + 'px';
          this.centerTooltip!.nativeElement.style.display = 'block';
        } else {
          this.centerTooltip!.nativeElement.style.display = 'none';
        }
      }
    },

    layout: {
      padding: 8,
    },
  };

  public plugins: Plugin<'doughnut'>[] = [
    {
      id: 'centerTitle',
      beforeDraw: (chart: Chart<any>): void => {
        const ctx: CanvasRenderingContext2D = chart.ctx;

        const values: number[] = chart.data.datasets
          .map((dataset: ChartDataset<'doughnut', number[]>): number[] => {
            return dataset.data;
          }, 0)
          .flat();
        const total: number = values.reduce((previous: number, current: number): number => {
          return previous + current;
        }, 0);

        const title: string = this.title ?? total.toString();
        const subtitle: string = this.subtitle ?? (total / values.length).toString();

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        const centerX: number = (chart.chartArea.left + chart.chartArea.right) / 2;
        const centerY: number = (chart.chartArea.top + chart.chartArea.bottom) / 2;

        ctx.font = '24px Arial';
        ctx.fillStyle = 'black';

        ctx.fillText(title, centerX, centerY - 12);

        ctx.font = '18px Arial';
        ctx.fillStyle = 'darkgrey';
        ctx.fillText(subtitle, centerX, centerY + 12);
      },
    },
  ];

  ngOnChanges(data: SimpleChanges): void {
    if ('data' in data && !this.arrayEquals(data['data'].previousValue, data['data'].currentValue)) {
      if (this.data === undefined) {
        this.options.plugins!.tooltip!.enabled = false;
      }
      this.dataSet = {
        labels: this.labels,
        datasets: [
          {
            type: 'doughnut',
            data: this.data ?? [1],
            backgroundColor: this.data !== undefined ? this.valueColors : ['gray'],
          },
        ],
      };
    }
  }

  private arrayEquals(a: any, b: any): boolean {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
  }
}
