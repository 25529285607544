<div class="dropdown">
  <button
    class="btn dropdown-toggle"
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    (click)="isOpened = !isOpened">
    {{ selectedFilter?.title }}
  </button>
  <div class="dropdown-menu" [class.show]="isOpened" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" *ngFor="let f of filters" (click)="onSelect(f)">{{f.title}}</a>
  </div>
</div>
