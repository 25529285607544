<ng-container *ngIf="show | async">
  <ng-container *ngIf="(isLoading$ | async) === false; else loadingIndicator">
    <ng-container *ngIf="productGroupsWithCertifications$ | async as productGroupsWithCertifications">
      <ng-container *ngFor="let productGroup of productGroupsWithCertifications; let productGroupIndex = index">
        <div class="content-box mb-3 rounded" *ngIf="(productGroup?.totalDealers ?? 0) > 0">
          <div class="container" *ngIf="!!productGroup.certificationGroups">
            <h3>
              {{ productGroup.name }} - {{ title | async }}
              <small>({{ productGroup.totalDealers }})</small>
              <app-tooltip-icon [tooltip]="dealerTooltip" placement="end"></app-tooltip-icon>
            </h3>

            <small>
              <strong>Date imported:</strong>
              {{ productGroup.createdAt | date: 'MMMM d y' }}
            </small>
            <div
              *ngFor="
                let certificationGroup of productGroup.certificationGroups;
                let isLast = last;
                let isFirst = first;
                let index = index
              "
              class="row"
              [ngClass]="{ 'padded-row': !isLast }">
              <app-certification-stats-block
                [isFirstBlock]="isFirst"
                [productGroup]="productGroup"
                [certificationGroup]="certificationGroup"></app-certification-stats-block>
              <div
                [ngClass]="{
                  'html2pdf__page-break': productGroupIndex === 0 ? index === 1 : index % 2 === 0 && index !== 0
                }"></div>
            </div>
            <div class="html2pdf__page-break"></div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="showProductGroup | async">
    <ng-container *ngIf="dealers$ | async as dealers">
      <app-dealers-certification-status-block [dealers]="dealers" />
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loadingIndicator><app-loading-indicator /></ng-template>

<ng-template #dealerTooltip>
  In between ( ) are the total of number dealers within that Product Group registered in the LXP.
  <br />
  <br />
  On the Dealers column the numbers show how many Dealers are reaching the registration and certification conditions.
  <br />
  <br />
  On the percentage column the numbers show the percentage of how many dealers are certified out of the total number of
  dealers ().
</ng-template>
