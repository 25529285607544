<div class="data">
  <div class="certification-stats">
    <h3 *ngIf="isFirstBlock">Dealer certification</h3>
    <table>
      <tr>
        <th [colSpan]="isFirstBlock ? 1 : 3">{{ certificationGroup.name }}</th>
        <th *ngIf="isFirstBlock">Dealers</th>
        <th *ngIf="isFirstBlock">Percentage</th>
      </tr>
      <tr>
        <th>Dealers with enrolled employees</th>
        <td>{{ certificationGroup.certifiedDealers!.atLeastOneEnrolled! | number: '1.0-0' }}</td>
        <td>{{ getPercentage(certificationGroup.certifiedDealers!.atLeastOneEnrolled!) | number: '1.0-0' }}%</td>
      </tr>
      <tr>
        <th>Dealers with at least one certified employee lvl I</th>
        <td>{{ certificationGroup.certifiedDealers!.atLeastOneCertifiedLevelOne! | number: '1.0-0' }}</td>
        <td>
          {{ getPercentage(certificationGroup.certifiedDealers!.atLeastOneCertifiedLevelOne!) | number: '1.0-0' }}%
        </td>
      </tr>
      <tr>
        <th>Dealers with 100% certified employees lvl I</th>
        <td>{{ certificationGroup.certifiedDealers!.allCertifiedLevelOne! | number: '1.0-0' }}</td>
        <td>{{ getPercentage(certificationGroup.certifiedDealers!.allCertifiedLevelOne!) | number: '1.0-0' }}%</td>
      </tr>
      <tr>
        <th>Dealers with at least one certified employee lvl II</th>
        <td>{{ certificationGroup.certifiedDealers!.atLeastOneCertifiedLevelTwo! | number: '1.0-0' }}</td>
        <td>
          {{ getPercentage(certificationGroup.certifiedDealers!.atLeastOneCertifiedLevelTwo!) | number: '1.0-0' }}%
        </td>
      </tr>
    </table>
  </div>
  <div class="graphs-container">
    <h3 *ngIf="isFirstBlock" class="graphs-title">
      Employee Certification&nbsp;
      <app-tooltip-icon [tooltip]="employeeCertificationTooltip" placement="left"></app-tooltip-icon>
    </h3>
    <div class="graphs">
      <ng-container *ngFor="let certification of certificationGroup.certifications">
        <div class="graph-wrapper">
          <h4 class="graph-title">{{ certification.shortName }}</h4>
          <app-doughnut-graph
            *ngIf="certification.enrolledEmployees"
            title="{{ certification.enrolledEmployees | number: '1.0-0' }}"
            subtitle="{{ certification.enrolled100 }}"
            [labels]="[
              'Completion 0% - 20%',
              'Completion 20% - 40%',
              'Completion 40% - 60%',
              'Completion 60% - 80%',
              'Completion 80% - 99%',
              'Completion 100%'
            ]"
            [data]="certification.graphData"
            [total]="certification.enrolledEmployees"
            [valueColors]="valueColors[productGroup.name]"
            [showCenterTooltip]="true">
            <div class="title">{{ certification.enrolledEmployees }} enrolled employees</div>
            <div class="subtitle">{{ certification.enrolled100 }} certified employees</div>
          </app-doughnut-graph>

          <app-doughnut-graph
            *ngIf="!certification.enrolledEmployees"
            title="{{ certification.enrolledEmployees | number: '1.0-0' }}"
            subtitle="{{ certification.enrolled100 }}"></app-doughnut-graph>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #employeeCertificationTooltip>
  The circle displays the certification progress of users from 0-100% (100% = Certified)
  <br />
  <br />
  The top number indicates how many users are enrolled into the certification (Level I or Level II)
  <br />
  <br />
  The bottom number indicates how many users are certified (Level I or Level II)
  <br />
  <br />
  Hover over the circle(s) to see more details.
  <br />
</ng-template>
