import { FilterSelectionType, FilterType } from '@shared/enums';
import { IFilterable, IFilterSettings } from '@shared/interfaces';

import { DealerGroup } from './dealer-group.model';

export class Certification implements IFilterable {
  public id!: string;
  public name!: string;
  public shortName!: string;
  public code?: string;
  public level?: number;
  public dealerGroup?: DealerGroup;

  public selected = false;
  public certifiedDealers?: {
    atLeastOneEnrolled: number;
    allCertifiedLevelOne: number;
    atLeastOneCertifiedLevelOne: number;
    atLeastOneCertifiedLevelTwo: number;
  };
  public enrolledEmployees?: number;
  public enrolled0to20?: number;
  public enrolled20to40?: number;
  public enrolled40to60?: number;
  public enrolled60to80?: number;
  public enrolled80to100?: number;
  public enrolled100?: number;

  public filterSettings: IFilterSettings = {
    type: FilterType.Certification,
    selectionType: FilterSelectionType.Multi,
  };

  public get graphData(): number[] {
    return [
      this.enrolled0to20!,
      this.enrolled20to40!,
      this.enrolled40to60!,
      this.enrolled60to80!,
      this.enrolled80to100!,
      this.enrolled100!,
    ];
  }

  public get percentage(): number {
    if (this.enrolled100 === 0) {
      return 0;
    } else if (this.enrolledEmployees === 0) {
      return 100;
    }

    return (this.enrolled100! / this.enrolledEmployees!) * 100;
  }
}
