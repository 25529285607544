import { TemplateRef } from '@angular/core';

export class TableData<T> {
  columns: TableHeader<T>[];

  constructor(columns: TableHeader<T>[]) {
    this.columns = columns;
  }

  get active(): TableHeader<T>[] {
    return this.columns.filter((value: TableHeader<T>) => value.visible);
  }

  get activeColumns(): TableHeader<T>[] {
    const items: TableHeader<T>[] = [];

    this.active.forEach((value: TableHeader<T>): void => {
      if (value.children.length > 0) {
        items.push(...value.children);
        return;
      }
      items.push(value);
    });

    return items;
  }

  get activeColumnMap(): { [p: string]: TableHeader<T> } {
    const items: { [key: string]: TableHeader<T> } = {};

    this.activeColumns.forEach((value: TableHeader<T>): void => {
      items[value.sortKey] = value;
    });

    return items;
  }
}

export class TableHeader<T> {
  title: string;

  // https://ichef.bbci.co.uk/images/ic/1008x567/p08rkqxl.jpg
  bobTheValueBuilder: (item: T) => string | number | null | Date;

  // http://cryptocouple.com/
  aliceTheDisplayValueBuilder: (item: T, builtValue: string | number | null | Date) => string | number | null | Date;

  excelExportValue: (item: T, builtValue: string | number | null | Date) => string | number | null | Date;

  align: ContentAlignment;
  visible: boolean;
  sortable: boolean;
  children: TableHeader<T>[];
  isChild: boolean;
  sortKey: string;
  width?: string;
  preserveBreaks: boolean;
  noWrap: boolean;
  template?: TemplateRef<object>;
  headerIsMultiline: boolean;
  textColorCallback?: (item: T) => string;

  constructor(
    title: string,
    bobTheValueBuilder: (item: T) => any,
    children: TableHeader<T>[] = [],
    options: Partial<{
      align: ContentAlignment;
      visible: boolean;
      sortable: boolean;
      isChild: boolean;
      sortKey: string;
      width: string;
      preserveBreaks: boolean;
      noWrap: boolean;
      template: TemplateRef<object>;
      headerIsMultiline: boolean;
      textColorCallback?: (item: T) => string;
      aliceTheDisplayValueBuilder?: (item: T, builtValue: any) => string | number | null | Date;
      excelExportValue?: (item: T, builtValue: any) => string | number | null | Date;
    }> = {},
  ) {
    this.title = title;
    this.bobTheValueBuilder = bobTheValueBuilder;

    // Fallback to just showing the value that was built by Bob
    this.aliceTheDisplayValueBuilder =
      options.aliceTheDisplayValueBuilder ??
      ((_: T, builtValue: string | number | null | Date): string | number | null | Date => builtValue);

    this.excelExportValue =
      options.excelExportValue ??
      ((_: T, builtValue: string | number | null | Date): string | number | null | Date => builtValue);

    this.children = children;

    this.align = options.align ?? ContentAlignment.CENTER;
    this.visible = options.visible ?? true;
    this.sortable = options.sortable ?? true;
    this.isChild = options.isChild ?? false;
    this.sortKey = options.sortKey ?? title;
    this.width = options.width;
    this.preserveBreaks = options.preserveBreaks ?? false;
    this.noWrap = options.noWrap ?? false;
    this.template = options.template;
    this.headerIsMultiline = options.headerIsMultiline ?? false;
    this.textColorCallback = options.textColorCallback;

    this.children.map((value: TableHeader<T>) => {
      value.isChild = true;
      return value;
    });
  }

  get alignedStart(): boolean {
    return this.align === ContentAlignment.START;
  }

  get alignedCenter(): boolean {
    return this.align === ContentAlignment.CENTER;
  }

  get alignedEnd(): boolean {
    return this.align === ContentAlignment.END;
  }
}

export enum ContentAlignment {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}
