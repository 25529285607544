<div class="content-box mt-5 rounded">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-2 mt-1">
        <span class="title">Filters</span>
      </div>
      <div class="col-12">
        <div class="row g-2">
          <span>Product groups</span>
          <app-select-button
            class="col-auto d-flex flex-wrap"
            [filterType]="filterTypes.DealerGroup"></app-select-button>
        </div>
      </div>

      <div class="col-12 mt-3" *ngIf="showFilter(filterTypes.DealerProductGroup) | async">
        <span>Products</span>
        <app-multiselect-dropdown
          [filterType]="filterTypes.DealerProductGroup"
          [isMultiselect]="true"></app-multiselect-dropdown>
      </div>

      <div class="col-12 mt-3" *ngIf="showFilter(filterTypes.Certification) | async">
        <span>Certifications</span>
        <app-multiselect-dropdown
          [filterType]="filterTypes.Certification"
          [isMultiselect]="true"></app-multiselect-dropdown>
      </div>

      <div class="col-12 mt-3">
        <span>Countries</span>
        <app-multiselect-dropdown [filterType]="filterTypes.Country" [isMultiselect]="true"></app-multiselect-dropdown>
      </div>

      <div class="col-12 mt-3" *ngIf="showFilter(filterTypes.Region) | async">
        <span>Areas / Regions</span>
        <app-multiselect-dropdown [filterType]="filterTypes.Region"></app-multiselect-dropdown>
      </div>
      <div class="col-12 mt-3" *ngIf="showFilter(filterTypes.DealerType) | async">
        <span>Dealer type</span>
        <app-multiselect-dropdown [filterType]="filterTypes.DealerType"></app-multiselect-dropdown>
      </div>

      <div class="col-12 mt-3" *ngIf="showFilter(filterTypes.Dealer) | async">
        <span>Dealers (code - name)</span>
        <app-multiselect-dropdown labelKey="filterName" [filterType]="filterTypes.Dealer"></app-multiselect-dropdown>
      </div>

      <div class="col-12 mt-3" *ngIf="showFilter(filterTypes.Employee) | async">
        <span>Employees</span>
        <app-multiselect-dropdown labelKey="name" [filterType]="filterTypes.Employee"></app-multiselect-dropdown>
      </div>

      <div class="row mt-5 mb-5 justify-content-center">
        <div class="col-auto">
          <button class="rounded p-1" (click)="resetFilter()">RESET FILTERS</button>
        </div>
      </div>
    </div>
  </div>
</div>
