import { EmployeeCourse } from '@shared/models/employee-course.model';
import { LearningPlan } from '@shared/models/learning-plan.model';
import { Type } from 'class-transformer';

export class EmployeeLearningPlan {
  @Type(() => LearningPlan)
  public learningPlan?: LearningPlan;
  @Type(() => EmployeeCourse)
  public employeeCourses?: EmployeeCourse[];

  public completionPercentage?: number;
  public completedAt?: Date;
}
