import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AddFilterData,
  AddFilters,
  AddLoading,
  FilterList,
  FiltersStateModel,
  LoadCertifications,
  LoadCountries,
  LoadDealerGroups,
  LoadDealerProductGroups,
  LoadDealersBy,
  LoadDealerTypes,
  LoadEmployeesByDealer,
  LoadFilters,
  LoadRegionsByCountries,
  RemoveFilters,
  ResetFilters,
  SelectFiltersFromRoute,
  SetPageLoadingFinished,
  UpdateRoute,
} from '@core/states';
import { areaTree, productTree } from '@misc/filter-node';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { FilterSelectionType, FilterType, LoadingData } from '@shared/enums';
import { IFilterable } from '@shared/interfaces';
import { Filter, FilterInputModel } from '@shared/models';
import * as immutable from 'object-path-immutable';
import { filter, firstValueFrom, lastValueFrom } from 'rxjs';

@State<FiltersStateModel>({
  name: 'filters',
  defaults: {
    filterInputModel: undefined,
    filters: {
      [FilterType.Default]: [],
      [FilterType.DealerGroup]: [],
      [FilterType.DealerProductGroup]: [],
      [FilterType.Certification]: [],
      [FilterType.Course]: [],
      [FilterType.Country]: [],
      [FilterType.Region]: [],
      [FilterType.DealerType]: [],
      [FilterType.Dealer]: [],
      [FilterType.Employee]: [],
    },
  },
})
@Injectable()
export class FiltersState {
  constructor(
    private store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  public static filtersByType<T = IFilterable>(filterType: FilterType): (state: FiltersStateModel) => T[] {
    return createSelector([FiltersState], (state: FiltersStateModel) => {
      return [...state.filters[filterType]].sort(
        (a: Filter, b: Filter): number => a.name?.localeCompare(b.name),
      ) as unknown as T[];
    });
  }

  public static activeFiltersByType<T = IFilterable>(filterType: FilterType): (state: FiltersStateModel) => T[] {
    return createSelector([FiltersState], (state: FiltersStateModel) => {
      return state.filters[filterType].filter((f: Filter): boolean => f.selected) as T[];
    });
  }

  public static hasSelectedParent(filterType: FilterType): (state: FiltersStateModel) => boolean {
    return createSelector([FiltersState], (state: FiltersStateModel): boolean => {
      let type: FilterType = FilterType.Default;
      switch (filterType) {
        case FilterType.DealerProductGroup:
        case FilterType.Certification:
          type = FilterType.DealerGroup;
          break;
        case FilterType.Region:
          type = FilterType.Country;
          break;
        case FilterType.DealerType:
          type = FilterType.Country;
          break;
        case FilterType.Dealer:
          type = FilterType.Country;
          break;
        case FilterType.Employee:
          type = FilterType.Dealer;
          break;
      }
      return state.filters[type].filter((f: Filter): boolean => f.selected).length > 0;
    });
  }

  @Selector()
  public static activeFiltersArray(state: FiltersStateModel): Filter[] {
    const activeFilters: Filter[] = [];
    for (const filterType of Object.values(FilterType)) {
      const filters: Filter[] = state.filters[filterType].filter((f: Filter): boolean => f.selected);
      if (filters.length > 0) {
        activeFilters.push(...filters);
      }
    }

    return activeFilters;
  }

  @Selector()
  public static activeFilters(state: FiltersStateModel): Partial<FilterList> {
    const filterList: Partial<FilterList> = {};

    for (const filterType of Object.values(FilterType)) {
      const filters: Filter[] = state.filters[filterType].filter((f: Filter): boolean => f.selected);
      if (filters.length > 0) {
        filterList[filterType] = filters;
      }
    }

    return filterList;
  }

  @Selector()
  public static activeFiltersTypes(state: FiltersStateModel): FilterType[] {
    const filterTypes: FilterType[] = [];

    for (const filterType of Object.values(FilterType)) {
      if (state.filters[filterType].some((f: Filter): boolean => f.selected)) {
        filterTypes.push(filterType);
      }
    }

    return filterTypes;
  }

  @Selector()
  public static filters(state: FiltersStateModel): FilterList {
    return state.filters;
  }

  @Selector()
  public static filterModel(state: FiltersStateModel): FilterInputModel | undefined {
    return state.filterInputModel;
  }

  @Action(LoadFilters)
  public async loadFilters(ctx: StateContext<FiltersStateModel>): Promise<void> {
    await lastValueFrom(ctx.dispatch([new LoadCountries(), new LoadDealerGroups()]));

    const { countries, dealerGroups } = this.store.snapshot();

    ctx.setState(
      patch({
        filters: patch({
          [FilterType.Default]: [],
          [FilterType.DealerGroup]: dealerGroups.dealerGroups,
          [FilterType.DealerProductGroup]: [],
          [FilterType.Certification]: [],
          [FilterType.Country]: countries.countries,
        }),
      }),
    );
  }

  @Action(AddFilterData)
  public addFilterData(ctx: StateContext<FiltersStateModel>, { filterType, filters }: AddFilterData): void {
    ctx.patchState({
      filters: { ...ctx.getState().filters, [filterType]: filters },
    });
  }

  @Action(AddFilters)
  public addFilters(ctx: StateContext<FiltersStateModel>, { filters }: AddFilters): void {
    let state: FilterList = ctx.getState().filters;

    for (const f of filters) {
      const index: number = state[f.filterSettings.type].findIndex((ff: IFilterable): boolean => ff.id === f.id);
      if (index > -1) {
        state = immutable.set(state, `${f.filterSettings.type}.${index}.selected`, true);
        if (f.filterSettings.selectionType === FilterSelectionType.Single) {
          // Deselect other options
          for (let i = 0; i < state[f.filterSettings.type].length; i++) {
            if (i !== index) {
              state = immutable.set(state, `${f.filterSettings.type}.${i}.selected`, false);
            }
          }
        }
      }
    }

    ctx.patchState({ filters: state, filterInputModel: this.getFilterModel(state) });
    ctx.dispatch(new UpdateRoute());
  }

  @Action(RemoveFilters)
  public removeFilters(ctx: StateContext<FiltersStateModel>, { filters }: RemoveFilters): void {
    let state: FilterList = ctx.getState().filters;
    for (const f of filters) {
      if (areaTree.asMap.includes(f.filterSettings.type) || productTree.asMap.includes(f.filterSettings.type)) {
        const children: FilterType[] =
          areaTree.findByType(f.filterSettings.type)?.children ??
          productTree.findByType(f.filterSettings.type)?.children ??
          [];
        for (const child of children) {
          state = immutable.set(state, `${child}`, []);
        }
      }

      const index: number = state[f.filterSettings.type].findIndex((ff: IFilterable): boolean => ff.id === f.id);
      if (index > -1) {
        state = immutable.set(state, `${f.filterSettings.type}.${index}.selected`, false);
      }

      if (f.filterSettings.type === FilterType.Region) {
        this.store.dispatch(new LoadDealersBy());
      }

      if (f.filterSettings.type === FilterType.DealerGroup) {
        this.store.dispatch(new LoadDealersBy());
        this.store.dispatch(new LoadDealerProductGroups());
      }
    }

    ctx.patchState({ filters: state, filterInputModel: this.getFilterModel(state) });
    ctx.dispatch(new UpdateRoute());
  }

  @Action(ResetFilters)
  public resetFilters(ctx: StateContext<FiltersStateModel>): void {
    ctx.dispatch(new UpdateRoute(true));
    let filters: FilterList = ctx.getState().filters;

    filters = immutable.set(filters, FilterType.DealerProductGroup, []);
    filters = immutable.set(filters, FilterType.Certification, []);
    filters = immutable.set(filters, FilterType.Region, []);
    filters = immutable.set(filters, FilterType.DealerType, []);
    filters = immutable.set(filters, FilterType.Dealer, []);
    filters = immutable.set(filters, FilterType.Employee, []);

    for (const f of Object.values(filters).flat()) {
      const index: number = filters[f.filterSettings.type].findIndex((ff: IFilterable): boolean => ff.id === f.id);
      filters = immutable.set(filters, `${f.filterSettings.type}.${index}.selected`, false);
    }

    ctx.patchState({ filters, filterInputModel: this.getFilterModel(filters) });
  }

  @Action(UpdateRoute)
  public async updateRouteParams(state: StateContext<FiltersStateModel>, { clear }: UpdateRoute): Promise<void> {
    if (clear) {
      window.history.replaceState(null, '', '');
      return;
    }
    const filters: Partial<FilterList> = state.getState().filters;

    const activeFilters: string[] = Object.values(filters)
      .flat()
      .filter((f: Filter): boolean => f.selected)
      .map((f: Filter): string => {
        return f.id;
      });

    window.history.replaceState(
      null,
      '',
      this.router
        .createUrlTree([], { relativeTo: this.activatedRoute, queryParams: { filters: activeFilters } })
        .toString(),
    );
  }

  @Action(SelectFiltersFromRoute)
  public async selectFiltersFromRoute(ctx: StateContext<FiltersStateModel>): Promise<void> {
    const params: Params = await firstValueFrom(
      this.activatedRoute.queryParams.pipe(
        filter((data: Params): boolean => {
          if (!data['filters']) {
            ctx.patchState({ filterInputModel: this.getFilterModel(ctx.getState().filters) });
            ctx.dispatch(new SetPageLoadingFinished());
            return false;
          }
          return true;
        }),
      ),
    );

    let filters: string | string[] = params['filters'];
    if (!Array.isArray(filters)) {
      filters = [filters];
    }

    if (filters.length > 0) {
      this.store.dispatch(new AddLoading(LoadingData.countryData));
    }

    const filtersList: FilterList = await this.updateFilterList(ctx.getState().filters, filters);
    ctx.patchState({ filters: filtersList, filterInputModel: this.getFilterModel(filtersList) });
    ctx.dispatch(new SetPageLoadingFinished());
  }

  private async updateFilterList(filterList: FilterList, filters: string[]): Promise<FilterList> {
    const unknownIds: string[] = [];

    for (const f of filters) {
      const filterable: IFilterable | undefined = Object.values(filterList)
        .flat()
        .find((ff: IFilterable): boolean => ff.id === f);

      if (!filterable) {
        unknownIds.push(f);
        continue;
      }

      const index: number = filterList[filterable.filterSettings.type].findIndex(
        (ff: IFilterable): boolean => ff.id === filterable.id,
      );

      if (filterList[filterable.filterSettings.type][index]?.selected) {
        continue;
      }

      if (index > -1) {
        filterList = immutable.set(filterList, `${filterable.filterSettings.type}.${index}.selected`, true);
      }

      switch (filterable.filterSettings.type) {
        case FilterType.DealerGroup:
          await lastValueFrom(
            this.store.dispatch([
              new LoadDealerProductGroups(filterList[FilterType.DealerGroup].map((f) => f.id)),
              new LoadCertifications(filterList[FilterType.DealerGroup].map((f) => f.id)),
              new LoadDealerTypes(
                filterList[FilterType.Country].filter((f) => f.selected).map((f) => f.entityId),
                filterList[FilterType.DealerGroup].filter((f) => f.selected).map((f) => f.id),
                filterList[FilterType.DealerProductGroup].filter((f) => f.selected).map((f) => f.id),
              ),
            ]),
          );

          filterList[FilterType.Certification] = await firstValueFrom<Filter[]>(
            this.store
              .select<Filter[]>(FiltersState.filtersByType(FilterType.Certification))
              .pipe(filter((filters: Filter[]) => !!filters)),
          );
          filterList[FilterType.DealerProductGroup] = await firstValueFrom<Filter[]>(
            this.store
              .select<Filter[]>(FiltersState.filtersByType(FilterType.DealerProductGroup))
              .pipe(filter((filters: Filter[]) => !!filters)),
          );

          break;
        case FilterType.Country:
          await lastValueFrom(
            this.store.dispatch([
              new LoadRegionsByCountries(filterList[FilterType.Country].map((f) => f.id)),
              new LoadDealersBy(
                filterList[FilterType.Country].filter((f) => f.selected).map((f) => f.entityId),
                filterList[FilterType.DealerGroup].filter((f) => f.selected).map((f) => f.id),
                filterList[FilterType.DealerProductGroup].filter((f) => f.selected).map((f) => f.id),
                filterList[FilterType.DealerType].filter((f) => f.selected).map((f) => f.id),
              ),
              new LoadDealerTypes(
                filterList[FilterType.Country].filter((f) => f.selected).map((f) => f.entityId),
                filterList[FilterType.DealerGroup].filter((f) => f.selected).map((f) => f.id),
                filterList[FilterType.DealerProductGroup].filter((f) => f.selected).map((f) => f.id),
              ),
            ]),
          );

          filterList[FilterType.Region] = await firstValueFrom<Filter[]>(
            this.store
              .select<Filter[]>(FiltersState.filtersByType(FilterType.Region))
              .pipe(filter((filters: Filter[]) => !!filters)),
          );

          filterList[FilterType.Dealer] = await firstValueFrom<Filter[]>(
            this.store
              .select<Filter[]>(FiltersState.filtersByType(FilterType.Dealer))
              .pipe(filter((filters: Filter[]) => !!filters)),
          );

          filterList[FilterType.DealerType] = await firstValueFrom<Filter[]>(
            this.store
              .select<Filter[]>(FiltersState.filtersByType(FilterType.DealerType))
              .pipe(filter((filters: Filter[]) => !!filters)),
          );

          break;
        case FilterType.Region:
          await lastValueFrom(
            this.store.dispatch([
              new LoadDealersBy(
                filterList[FilterType.Region].filter((f) => f.selected).map((f) => f.entityId),
                filterList[FilterType.DealerGroup].filter((f) => f.selected).map((f) => f.id),
                filterList[FilterType.DealerProductGroup].filter((f) => f.selected).map((f) => f.id),
                filterList[FilterType.DealerType].filter((f) => f.selected).map((f) => f.id),
              ),
              new LoadDealerTypes(
                filterList[FilterType.Country].filter((f) => f.selected).map((f) => f.entityId),
                filterList[FilterType.DealerGroup].filter((f) => f.selected).map((f) => f.id),
                filterList[FilterType.DealerProductGroup].filter((f) => f.selected).map((f) => f.id),
              ),
            ]),
          );

          filterList[FilterType.Dealer] = await firstValueFrom<Filter[]>(
            this.store
              .select<Filter[]>(FiltersState.filtersByType(FilterType.Dealer))
              .pipe(filter((filters: Filter[]) => !!filters)),
          );
          filterList[FilterType.DealerType] = await firstValueFrom<Filter[]>(
            this.store
              .select<Filter[]>(FiltersState.filtersByType(FilterType.DealerType))
              .pipe(filter((filters: Filter[]) => !!filters)),
          );
          break;
        case FilterType.DealerType:
          await lastValueFrom(
            this.store.dispatch(
              new LoadDealersBy(
                filterList[FilterType.Region].filter((f) => f.selected).map((f) => f.entityId),
                filterList[FilterType.DealerGroup].filter((f) => f.selected).map((f) => f.id),
                filterList[FilterType.DealerProductGroup].filter((f) => f.selected).map((f) => f.id),
                filterList[FilterType.DealerType].filter((f) => f.selected).map((f) => f.id),
              ),
            ),
          );

          filterList[FilterType.Dealer] = await firstValueFrom<Filter[]>(
            this.store
              .select<Filter[]>(FiltersState.filtersByType(FilterType.Dealer))
              .pipe(filter((filters: Filter[]) => !!filters)),
          );
          break;
        case FilterType.Dealer:
          await lastValueFrom(this.store.dispatch(new LoadEmployeesByDealer(filterList[FilterType.Dealer][index].id)));

          filterList[FilterType.Employee] = await firstValueFrom<Filter[]>(
            this.store
              .select<Filter[]>(FiltersState.filtersByType(FilterType.Employee))
              .pipe(filter((filters: Filter[]) => !!filters)),
          );
          break;
      }
    }

    if (unknownIds.length > 0) {
      return this.updateFilterList(filterList, unknownIds);
    }

    return filterList;
  }

  private getFilterModel(filters: Partial<FilterList>): FilterInputModel {
    const filterInputModel: FilterInputModel = new FilterInputModel();

    for (const filterType of Object.values(FilterType)) {
      if (filters[filterType] !== undefined) {
        const selectedFilters = filters[filterType]!.filter((f: Filter): boolean => f.selected);
        if (selectedFilters.length > 0) {
          if (filterType === FilterType.DealerGroup) {
            filterInputModel.dealerGroupIds = selectedFilters.map((f: Filter): string => f.id);
          }
          if (filterType === FilterType.DealerProductGroup) {
            filterInputModel.dealerProductGroupIds = selectedFilters.map((f: Filter): string => f.id);
          }
          if (filterType === FilterType.Certification) {
            filterInputModel.certificationIds = selectedFilters.map((f: Filter): string => f.id);
          }
          if (filterType === FilterType.Country) {
            filterInputModel.countryEntityIds = selectedFilters.map((f: Filter): string => f.entityId);
          }
          if (filterType === FilterType.Region) {
            filterInputModel.regionEntityId = selectedFilters[0].entityId;
          }
          if (filterType === FilterType.DealerType) {
            filterInputModel.dealerTypes = selectedFilters.map((f: Filter): string => f.id);
          }
          if (filterType === FilterType.Dealer) {
            filterInputModel.dealerEntityId = selectedFilters[0].entityId;
          }
          if (filterType === FilterType.Employee) {
            filterInputModel.employeeEntityId = selectedFilters[0].entityId;
          }
        }
      }
    }

    return filterInputModel;
  }
}
