import { Component } from '@angular/core';
import { DashboardState, FiltersState } from '@core/states';
import { areaTree } from '@misc/filter-node';
import { Select } from '@ngxs/store';
import { FilterType, LoadingData } from '@shared/enums';
import { Country, Dealer, DealerGroup, Filter } from '@shared/models';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-general-data',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.scss'],
})
export class GeneralDataComponent {
  @Select(FiltersState.activeFiltersArray)
  public activeFilters$!: Observable<Filter[]>;

  @Select(DashboardState.productGroupsWithCertifications)
  public productGroupsWithCertifications$!: Observable<DealerGroup[]>;

  @Select(DashboardState.dealers)
  public dealers$!: Observable<Dealer[]>;

  @Select(DashboardState.isLoading([LoadingData.generalData, LoadingData.dealerData]))
  public isLoading$!: Observable<boolean>;

  public get show(): Observable<boolean> {
    return this.activeFilters$.pipe(
      map(
        (filters: Filter[]): boolean =>
          ![FilterType.Dealer, FilterType.Employee].includes(areaTree.findBranchEnding(filters)),
      ),
    );
  }

  public get title(): Observable<string> {
    return this.activeFilters$.pipe(
      map((filters: Filter[]): string => {
        const filter: Country[] | undefined = filters.filter(
          (f: Filter) => f.filterSettings.type == FilterType.Country && f.selected,
        );
        if (!!filter && filter.length > 0) {
          return (filter as Country[])?.map((f) => f.name).join(', ') ?? '';
        }
        return 'Worldwide';
      }),
    );
  }

  public get showProductGroup(): Observable<boolean> {
    return this.activeFilters$.pipe(
      map((filters: Filter[]): boolean => {
        const types: FilterType[] = filters.map((filter: Filter): FilterType => filter.filterSettings.type);

        return (
          types.includes(FilterType.DealerGroup) &&
          (types.includes(FilterType.Country) || types.includes(FilterType.Region)) &&
          !types.includes(FilterType.Dealer)
        );
      }),
    );
  }
}
