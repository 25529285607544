import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {
  ArrayDisplayComponent,
  CertificationStatsBlockComponent,
  CourseStatusByCertificationBlockComponent,
  DealersCertificationStatusBlockComponent,
  DoughnutGraphComponent,
  DropdownComponent,
  EmployeeBlockComponent,
  FilterBoxComponent,
  IconButtonComponent,
  LoadingIndicatorComponent,
  MultiselectDropdownComponent,
  SelectButtonComponent,
  SidebarComponent,
  TableComponent,
  TooltipIconComponent,
} from '@shared/components';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgChartsModule } from 'ng2-charts';

import { ReplaceLineBreaks } from './pipes/newline-to-break.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

const components = [
  ArrayDisplayComponent,
  CertificationStatsBlockComponent,
  CourseStatusByCertificationBlockComponent,
  DealersCertificationStatusBlockComponent,
  DoughnutGraphComponent,
  DropdownComponent,
  EmployeeBlockComponent,
  FilterBoxComponent,
  IconButtonComponent,
  LoadingIndicatorComponent,
  MultiselectDropdownComponent,
  ReplaceLineBreaks,
  TruncatePipe,
  SelectButtonComponent,
  SidebarComponent,
  TableComponent,
  TooltipIconComponent,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    NgChartsModule,
    NgOptimizedImage,
    NgbTooltipModule,
  ],
  providers: [DatePipe],
})
export class SharedModule {
  public constructor(private library: FaIconLibrary) {
    this.library.addIcons(faTimes);
  }
}
