export class LoadDealers {
  public static readonly type = '[Dealers] Load dealers';

  public constructor() {}
}

export class LoadDealersBy {
  public static readonly type = '[Dealers] Load dealers by ';

  public constructor(
    public entityIds?: string[],
    public dealerGroupIds?: string[],
    public dealerProductGroupIds?: string[],
    public dealerTypes?: string[],
  ) {}
}

export class LoadDealerTypes {
  public static readonly type = '[Dealers] Load dealer types';

  public constructor(
    public entityIds?: string[],
    public dealerGroupIds?: string[],
    public dealerProductGroupIds?: string[],
  ) {}
}
