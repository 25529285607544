import { FilterType } from '@shared/enums';
import { IFilterable } from '@shared/interfaces';

export class FilterNode {
  constructor(
    public type: FilterType,
    public child?: FilterNode,
  ) {}

  public get children(): FilterType[] {
    if (!this.child) return [];
    return this.child.asMap;
  }

  public get asMap(): FilterType[] {
    if (!this.child) return [this.type];
    return [this.type, ...this.child.asMap];
  }

  public findByType(type: FilterType): FilterNode | null {
    if (this.type === type) return this;
    if (!this.child) return null;
    return this.child.findByType(type);
  }

  public findBranchEnding(filters: IFilterable[]): FilterType {
    const types: FilterType[] = filters
      .filter((filter: IFilterable) => filter.selected)
      .map((filter: IFilterable) => filter.filterSettings.type);

    return this.findDeepestLevel(types)?.type ?? FilterType.Default;
  }

  protected findDeepestLevel(types: FilterType[]): FilterNode | null {
    if (!this.child && types.includes(this.type)) return this;
    if (!this.child) return null;
    const childLevel: FilterNode | null = this.child.findDeepestLevel(types);
    if (childLevel) return childLevel;
    if (types.includes(this.type)) return this;
    return null;
  }
}

export const productTree: FilterNode = new FilterNode(
  FilterType.DealerGroup,
  new FilterNode(FilterType.DealerProductGroup, new FilterNode(FilterType.Certification)),
);

export const areaTree: FilterNode = new FilterNode(
  FilterType.Country,
  new FilterNode(FilterType.Region, new FilterNode(FilterType.Dealer, new FilterNode(FilterType.Employee))),
);
