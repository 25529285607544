import { FilterSelectionType, FilterType } from '@shared/enums';
import { IFilterable, IFilterSettings } from '@shared/interfaces';
import { DealerProductGroup } from '@shared/models/dealer-product-group.model';
import { EmployeeCertification } from '@shared/models/employee-certification.model';
import { EmployeeCourse } from '@shared/models/employee-course.model';
import { EmployeeLearningPlan } from '@shared/models/employee-learning-plan.model';
import { EmployeeRole } from '@shared/models/employee-role.model';
import { Type } from 'class-transformer';

export class Employee implements IFilterable {
  @Type(() => EmployeeCertification)
  public certifications?: EmployeeCertification[];
  @Type(() => EmployeeCourse)
  public courses?: EmployeeCourse[];
  @Type(() => EmployeeLearningPlan)
  public learningPlans?: EmployeeLearningPlan[];
  @Type(() => EmployeeRole)
  public roles?: EmployeeRole[];
  @Type(() => EmployeeLearningPlan)
  public productGroups?: DealerProductGroup[];

  public id!: string;
  public entityId!: string;
  public name!: string;
  public username!: string;
  public firstName!: string;
  public lastName!: string;
  public lastLoginDate!: Date;
  public registrationDate!: Date;

  public selected = false;
  public filterSettings: IFilterSettings = {
    type: FilterType.Employee,
    selectionType: FilterSelectionType.Single,
  };
}
