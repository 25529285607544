import { FilterType } from '@shared/enums';
import { IFilterable } from '@shared/interfaces';

export class LoadFilters {
  public static readonly type = '[Filter] Load filters';

  public constructor() {}
}

export class LoadFiltersForType {
  public static readonly type = '[Filter] Load filters for type';

  public constructor(public type: FilterType) {}
}

export class AddFilterData {
  public static readonly type = '[Filter] Add filter data';

  public constructor(
    public filterType: FilterType,
    public filters: IFilterable[],
  ) {}
}

export class AddFilters {
  public static readonly type = '[Filter] Add filters';

  public constructor(public filters: IFilterable[]) {}
}

export class RemoveFilters {
  public static readonly type = '[Filter] Remove filters';

  public constructor(public filters: IFilterable[]) {}
}

export class ResetFilters {
  public static readonly type = '[Filter] Reset filters';

  public constructor() {}
}

export class UpdateRoute {
  public static readonly type = '[Filter] Update router parameters';

  public constructor(public clear: boolean = false) {}
}

export class SelectFiltersFromRoute {
  public static readonly type = '[Filter] Select filters from route';

  public constructor() {}
}
