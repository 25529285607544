<div class="content-box p-2">
  <div class="row">
    <div class="col-9">
      <span class="text-medium d-block">{{ type }}</span>
      <span class="d-block no-wrap">
        <app-array-display [values]="values" />
      </span>
    </div>
    <div class="col-3 d-flex flex-column justify-content-center">
      <fa-icon [classes]="['pointer']" size="lg" [icon]="faTimes" (click)="onCloseClicked()"></fa-icon>
    </div>
  </div>
</div>
