import { FilterSelectionType, FilterType } from '@shared/enums';
import { IFilterable, IFilterSettings } from '@shared/interfaces';
import { Type } from 'class-transformer';

import { CertificationDealerData } from './certification-dealer-data.model';
import { DealerCertificationData } from './dealer-certification-data.model';
import { Employee } from './employee.model';
import { Region } from './region.model';

export class Dealer implements IFilterable {
  @Type(() => CertificationDealerData)
  public certificationDealerData?: CertificationDealerData[];
  @Type(() => DealerCertificationData)
  public dealerCertificationData?: DealerCertificationData[];
  @Type(() => Employee)
  public employees?: Employee[];
  @Type(() => Region)
  public region?: Region;

  public id!: string;
  public entityId!: string;
  public name!: string;
  public code?: string;
  public dealerType!: string;
  public filterName?: string;
  public totalEmployees?: number;
  public hasLvlTwoCertification: boolean = false;
  public selected = false;

  public filterSettings: IFilterSettings = {
    type: FilterType.Dealer,
    selectionType: FilterSelectionType.Single,
  };
}
