<ng-multiselect-dropdown
  [placeholder]="placeholder"
  [settings]="dropdownSettings"
  [data]="(filters$ | async) ?? []"
  [(ngModel)]="selectedItems"
  (onSelect)="onItemToggle($event, true)"
  (onDeSelect)="onItemToggle($event, false)"
  (onDropDownClose)="onDropDownClose()"
  (onSelectAll)="onToggleAll(true)"
  (onDeSelectAll)="onToggleAll(false)"></ng-multiselect-dropdown>
