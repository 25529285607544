import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { DealerGroupsStateModel, LoadDealerGroups } from '@core/states/dealer-groups';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DealerGroup } from '@shared/models';
import { Observable, tap } from 'rxjs';

@State<DealerGroupsStateModel>({
  name: 'dealerGroups',
  defaults: {
    dealerGroups: [],
  },
})
@Injectable()
export class DealerGroupsState {
  constructor(private apiService: ApiService) {}

  @Selector()
  static dealerGroups(state: DealerGroupsStateModel): DealerGroup[] {
    return state.dealerGroups;
  }

  @Action(LoadDealerGroups)
  public loadProductGroups(ctx: StateContext<DealerGroupsStateModel>): Observable<DealerGroup[]> {
    return this.apiService.dealerGroups().pipe(
      tap((dealerGroups: DealerGroup[]): void => {
        ctx.patchState({ dealerGroups });
      }),
    );
  }
}
