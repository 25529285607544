import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { DealerProductGroupsStateModel, LoadDealerProductGroups } from '@core/states/dealer-product-groups';
import { AddFilterData, FiltersState } from '@core/states/filters';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FilterType } from '@shared/enums';
import { DealerProductGroup } from '@shared/models';
import { Observable, tap } from 'rxjs';

@State<DealerProductGroupsStateModel>({
  name: 'dealerProductGroups',
  defaults: {
    dealerProductGroups: [],
  },
})
@Injectable()
export class DealerProductGroupsState {
  constructor(
    private apiService: ApiService,
    private store: Store,
  ) {}

  @Selector()
  static dealerGroups(state: DealerProductGroupsStateModel): DealerProductGroup[] {
    return state.dealerProductGroups;
  }

  @Action(LoadDealerProductGroups)
  public loadProductGroups(
    ctx: StateContext<DealerProductGroupsStateModel>,
    { dealerGroupIds }: LoadDealerProductGroups,
  ): Observable<DealerProductGroup[]> {
    if (!dealerGroupIds) {
      dealerGroupIds = this.store
        .selectSnapshot(FiltersState.activeFiltersByType(FilterType.DealerGroup))
        .map((f) => f.id);
    }

    return this.apiService.dealerProductGroups(dealerGroupIds.length === 0 ? undefined : dealerGroupIds).pipe(
      tap((dealerProductGroups: DealerProductGroup[]): void => {
        ctx.patchState({ dealerProductGroups });
        this.store.dispatch(new AddFilterData(FilterType.DealerProductGroup, dealerProductGroups));
      }),
    );
  }
}
