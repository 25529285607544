import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { CountriesStateModel, LoadCountries } from '@core/states/countries';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Country } from '@shared/models';
import { Observable, tap } from 'rxjs';

@State<CountriesStateModel>({
  name: 'countries',
  defaults: {
    countries: [],
  },
})
@Injectable()
export class CountriesState {
  public constructor(private apiService: ApiService) {}

  @Selector()
  static countries(state: CountriesStateModel): Country[] {
    return state.countries;
  }

  @Action(LoadCountries)
  public loadCountries(ctx: StateContext<CountriesStateModel>): Observable<Country[]> {
    return this.apiService.countries().pipe(
      tap((countries: Country[]): void => {
        ctx.patchState({ countries });
      }),
    );
  }
}
