import { Type } from 'class-transformer';

import { Certification } from './certification.model';

export class CertificationGroup {
  @Type(() => Certification)
  public certifications!: Certification[];

  public id!: string;
  public code?: string;
  public name!: string;

  public certifiedDealers?: {
    atLeastOneEnrolled: number;
    allCertifiedLevelOne: number;
    atLeastOneCertifiedLevelOne: number;
    atLeastOneCertifiedLevelTwo: number;
  };

  public get enrolledEmployees(): number {
    if (this.certifications.length == 0) {
      return 0;
    }
    return this.certifications
      .map((c) => c.enrolledEmployees ?? 0)
      .reduce((prev: number, curr: number) => (prev += curr));
  }
}
