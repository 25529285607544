import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthenticateComponent } from './components/authenticated/authenticate.component';
import { LoginComponent } from './components/login/login.component';
import { AuthState } from './states/auth';

@NgModule({
  declarations: [AuthenticateComponent, LoginComponent],
  imports: [
    AuthRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxsModule.forFeature([AuthState]),
  ],
})
export class AuthModule {}
