<h2>
  {{ employee.name }}
  <app-tooltip-icon [tooltip]="employeeBlockTooltip" placement="end"></app-tooltip-icon>
</h2>
<div class="employee-block">
  <table class="employee-info-table">
    <tr>
      <th>Job Role(s)</th>
      <td [innerHTML]="employeeRoles | nl2br"></td>
    </tr>
    <tr>
      <th>Product(s)</th>
      <td>{{ employeeProducts }}</td>
    </tr>
    <tr>
      <th>Last login date</th>
      <td>{{ employee.lastLoginDate | date: 'dd-MM-yyyy' }}</td>
    </tr>
    <tr>
      <th>Creation date</th>
      <td>{{ employee.registrationDate | date: 'dd-MM-yyyy' }}</td>
    </tr>
    <tr>
      <th>Completed certifications</th>
      <td [innerHTML]="completedCertifications | nl2br"></td>
    </tr>
  </table>
  <div class="graph-wrapper">
    <app-doughnut-graph
      class="graph"
      subtitle="courses"
      [data]="courseGraphValues"
      [labels]="courseGraphLabels"
      [valueColors]="courseGraphColors"></app-doughnut-graph>
    <table class="graph-table">
      <tr>
        <th></th>
        <th>Courses</th>
        <th>Percentage</th>
      </tr>
      <tr *ngFor="let item of courseGraphData | keyvalue">
        <th>
          <div class="legend-box" [ngClass]="courseStatusColor(item.key)"></div>
          {{ item.key }}
        </th>
        <td>{{ item.value }}</td>
        <td>{{ (item.value / totalCourses) * 100 | number: '1.1-1' }} %</td>
      </tr>
    </table>
  </div>
</div>

<ng-template #employeeBlockTooltip>
  COURSES: all courses connected to certification
  <br />
  <br />
  COMPLETED CERTIFICATION: current LXP certification status, if the certification in the dashboard is showing < 100% it
  means the user will lose this certification at the beginning of a new quarter.
</ng-template>
