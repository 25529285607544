<ng-template #statusIndicator let-statusDot>
  <div class="status-dot" [ngClass]="'status-dot-' + statusDot"></div>
</ng-template>

<ng-template #certificationData let-certificationData>
  <span [style.color]="certificationData.textColorEnrolled">{{ certificationData.enrolledEmployees }}</span>
  /
  <span [style.color]="certificationData.textColorLevelOne">{{ certificationData.certififiedLevelOne }}</span>
  <ng-container *ngIf="certificationData.certififiedLevelTwo !== undefined">
    /
    <span [style.color]="certificationData.textColorLevelTwo">{{ certificationData.certififiedLevelTwo }}</span>
  </ng-container>
</ng-template>

<div *ngIf="(isLoading$ | async) === false; else loadingIndicator">
  <div class="content-box mt-5 rounded">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <app-table
            title="Dealers certification status"
            [horizontalScroll]="true"
            [columns]="dealersCertificationStatusTable"
            [data]="dealers"
            [filters]="tableFilters"
            [enableExportToExcel]="true"
            [onRowClicked]="onDealerRowClicked"
            [tooltip]="dealerCertificationStatusTooltip"
            tooltipPlacement="end"
            tooltipWidth="400"
            tooltipClass="dealerCertificationStatusTooltip"></app-table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingIndicator></ng-template>

<ng-template #dealerCertificationStatusTooltip>
  GREEN: All Certification requirements are reached
  <br />
  YELLOW: One or more LVL 2 Certifications requirements are not reached
  <br />
  ORANGE: One or more LVL1 Certifications requirements are not reached
  <br />
  RED: One or more Certifications are missing employee(s) enrollment
  <br />
  <br />
  CERTIFICATION
  <br />
  NR: number of employees enrolled in the certification
  <br />
  LVL1: number of employees that completed the LVL 1 certification
  <br />
  LVL 2: number of employees that completed the LVL 2 certification
  <br />
  <br />
  If one of the certification requirements is not reached, the number will appear in RED
</ng-template>
