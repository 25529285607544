import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { DealersStateModel, LoadDealers, LoadDealersBy, LoadDealerTypes } from '@core/states/dealers';
import { AddFilterData, FiltersState } from '@core/states/filters';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FilterType } from '@shared/enums';
import { Country, Dealer, DealerGroup, DealerType, Region } from '@shared/models';
import { firstValueFrom } from 'rxjs';

@State<DealersStateModel>({
  name: 'dealers',
  defaults: {
    dealers: [],
    dealerTypes: [],
  },
})
@Injectable()
export class DealersState {
  constructor(
    private apiService: ApiService,
    private store: Store,
  ) {}

  @Selector()
  static dealers(state: DealersStateModel): Dealer[] {
    return state.dealers;
  }

  @Action(LoadDealers)
  public async loadDealers(ctx: StateContext<DealersStateModel>): Promise<void> {
    const dealers = await this.apiService.dealers().toPromise();

    ctx.patchState({ dealers });
  }

  @Action(LoadDealersBy)
  public async loadDealersBy(
    ctx: StateContext<DealersStateModel>,
    { entityIds, dealerGroupIds, dealerProductGroupIds, dealerTypes }: LoadDealersBy,
  ): Promise<void> {
    entityIds ??= this.store
      .selectSnapshot(FiltersState.activeFiltersByType(FilterType.Region))
      .map((f) => (f as Region).entityId);

    if (entityIds.length === 0) {
      entityIds = this.store
        .selectSnapshot(FiltersState.activeFiltersByType(FilterType.Country))
        .map((f) => (f as Country).entityId);
    }

    dealerGroupIds ??= this.store
      .selectSnapshot(FiltersState.activeFiltersByType(FilterType.DealerGroup))
      .map((f) => (f as DealerGroup).id);

    dealerProductGroupIds ??= this.store
      .selectSnapshot(FiltersState.activeFiltersByType(FilterType.DealerProductGroup))
      .map((f) => (f as DealerGroup).id);

    dealerTypes ??= this.store
      .selectSnapshot(FiltersState.activeFiltersByType(FilterType.DealerType))
      .map((f) => (f as DealerType).id);

    if (entityIds.length == 0) entityIds = undefined;
    if (dealerGroupIds.length == 0) dealerGroupIds = undefined;
    if (dealerProductGroupIds.length == 0) dealerProductGroupIds = undefined;
    if (dealerTypes.length == 0) dealerTypes = undefined;

    const dealers: Dealer[] = await firstValueFrom(
      this.apiService.dealersBy(entityIds, dealerGroupIds, dealerProductGroupIds, dealerTypes),
    );

    if (dealers !== undefined) {
      ctx.patchState({ dealers });
      this.store.dispatch(new AddFilterData(FilterType.Dealer, dealers));
    }
  }

  @Action(LoadDealerTypes)
  public async loadDealerTypes(
    ctx: StateContext<DealersStateModel>,
    { entityIds, dealerGroupIds, dealerProductGroupIds }: LoadDealersBy,
  ): Promise<void> {
    entityIds ??= this.store
      .selectSnapshot(FiltersState.activeFiltersByType(FilterType.Region))
      .map((f) => (f as Region).entityId);

    if (entityIds.length === 0) {
      entityIds = this.store
        .selectSnapshot(FiltersState.activeFiltersByType(FilterType.Country))
        .map((f) => (f as Country).entityId);
    }

    dealerGroupIds ??= this.store
      .selectSnapshot(FiltersState.activeFiltersByType(FilterType.DealerGroup))
      .map((f) => (f as DealerGroup).id);

    dealerProductGroupIds ??= this.store
      .selectSnapshot(FiltersState.activeFiltersByType(FilterType.DealerProductGroup))
      .map((f) => (f as DealerGroup).id);

    if (entityIds.length == 0) entityIds = undefined;
    if (dealerGroupIds.length == 0) dealerGroupIds = undefined;
    if (dealerProductGroupIds.length == 0) dealerProductGroupIds = undefined;

    const dealerTypes: DealerType[] = await firstValueFrom(
      this.apiService.dealerTypes(entityIds, dealerGroupIds, dealerProductGroupIds),
    );

    if (dealerTypes !== undefined) {
      ctx.patchState({ dealerTypes });
      this.store.dispatch(new AddFilterData(FilterType.DealerType, dealerTypes));
    }
  }
}
