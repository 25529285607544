<canvas baseChart [data]="dataSet" [type]="chartType" [options]="options" [plugins]="plugins"></canvas>
<div #centerTooltip id="centerTooltip">
  <ng-content></ng-content>
</div>
<div class="tooltip">
  <div class="title"></div>
  <div class="data">
    <div class="value"></div>
    <div class="percentage"></div>
  </div>
</div>
