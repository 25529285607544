import { FilterSelectionType, FilterType } from '@shared/enums';
import { IFilterable, IFilterSettings } from '@shared/interfaces';
import { CertificationGroup } from '@shared/models';
import { Type } from 'class-transformer';

export class DealerGroup implements IFilterable {
  @Type(() => CertificationGroup)
  public certificationGroups?: CertificationGroup[];

  public id!: string;
  public name!: string;
  public totalDealers?: number;
  public createdAt?: Date;

  public selected = false;

  public filterSettings: IFilterSettings = {
    type: FilterType.DealerGroup,
    selectionType: FilterSelectionType.Multi,
  };
}
