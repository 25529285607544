export class TableFilter<T> {
  public readonly name: string;
  public readonly filters: TableFilterOption<T>[];

  constructor(name: string, filters: TableFilterOption<T>[]) {
    this.name = name;
    this.filters = filters;
  }
}

export class TableFilterOption<T> {
  public readonly title: string;
  public readonly filter: (item: T) => boolean;

  constructor(title: string, filter: (item: T) => boolean) {
    this.title = title;
    this.filter = filter;
  }
}
