<span>
  <ng-container *ngFor="let value of truncatedValues; let last = last; let i = index">
    <span [title]="values[i]">{{ value }}</span>
    <ng-container *ngIf="!last">{{ ', ' }}</ng-container>
  </ng-container>
  <ng-container *ngIf="overflowItems">
    <span [title]="overflowItems.join(', ')" class="overflow-tooltip">
      {{ ', +' + (values.length - maxDisplayCount) }}
    </span>
  </ng-container>
</span>
