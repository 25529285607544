import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of, switchMap, tap } from 'rxjs';

import { Authenticate, AuthenticateLogin, RefreshToken } from './auth.actions';
import { AuthStateModel } from './auth.state-model';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
  },
})
@Injectable()
export class AuthState {
  constructor(private readonly apiService: ApiService) {}

  @Selector()
  static token(state: AuthStateModel): string | null | undefined {
    return state.token;
  }

  @Action(Authenticate)
  public authenticate(
    ctx: StateContext<AuthStateModel>,
    { userId, username, authCode, hash }: Authenticate,
  ): Observable<string> {
    return this.apiService.exchangeAuthCode(userId, username, authCode, hash).pipe(
      switchMap((token: string) => of(token)),
      tap((token: string) => ctx.patchState({ token })),
      tap((token: string) => this.apiService.setApiKeyHeader(token)),
      tap((token: string) => localStorage.setItem('token', token)),
    );
  }

  @Action(AuthenticateLogin)
  public authenticateLogin(
    ctx: StateContext<AuthStateModel>,
    { username, password }: AuthenticateLogin,
  ): Observable<string> {
    localStorage.removeItem('token');
    return this.apiService.login(username, password).pipe(
      switchMap((token: string) => of(token)),
      tap((token: string) => ctx.patchState({ token })),
      tap((token: string) => this.apiService.setApiKeyHeader(token)),
      tap((token: string) => localStorage.setItem('token', token)),
    );
  }

  @Action(RefreshToken)
  public refreshToken(ctx: StateContext<AuthStateModel>): Observable<string> {
    const token = localStorage.getItem('token');

    return this.apiService.refreshToken(token!).pipe(
      switchMap((token: string) => of(token)),
      tap((token: string) => ctx.patchState({ token })),
      tap((token: string) => this.apiService.setApiKeyHeader(token)),
      tap((token: string) => localStorage.setItem('token', token)),
    );
  }
}
