import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FiltersState } from '@core/states';
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngxs/store';
import { FilterType } from '@shared/enums';
import { Filter } from '@shared/models';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-box',
  templateUrl: './filter-box.component.html',
  styleUrls: ['./filter-box.component.scss'],
})
export class FilterBoxComponent implements OnInit, OnDestroy {
  @Input() type: FilterType = FilterType.Default;

  @Output() closeFilter: EventEmitter<void> = new EventEmitter<void>();

  activeTypeFilters$!: Observable<Filter[]>;
  private activeTypeFiltersSub: Subscription = new Subscription();

  public values: string[] = [];

  faTimes: IconDefinition = faTimes;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.activeTypeFilters$ = this.store.select<Filter[]>(FiltersState.activeFiltersByType(this.type));

    this.activeTypeFiltersSub = this.activeTypeFilters$.subscribe((items: Filter[]): void => {
      this.values = items.map((item) => item.name);
    });
  }

  onCloseClicked(): void {
    this.closeFilter.emit();
  }

  ngOnDestroy(): void {
    this.activeTypeFiltersSub?.unsubscribe();
  }
}
