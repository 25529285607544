import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import {
  DashboardState,
  FilterList,
  FiltersState,
  LoadDealerGroups,
  LoadDealerProductGroups,
  LoadFilters,
  RemoveFilters,
  SelectFiltersFromRoute,
} from '@core/states';
import {
  LoadDealerGroupsWithCertifications,
  LoadDealersCertificationData,
  LoadDealerWithCertifications,
  LoadEmployeesWithCertifications,
  LoadEmployeeWithCertifications,
} from '@core/states/dashboard';
import { Select, Store } from '@ngxs/store';
import { FilterType } from '@shared/enums';
import { IFilterable } from '@shared/interfaces';
import { Dealer, Filter, FilterInputModel } from '@shared/models';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import { DateTime } from 'luxon';
import { debounceTime, lastValueFrom, map, Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @Select(FiltersState.activeFilters)
  public activeFilters$!: Observable<Partial<FilterList>>;

  @Select(FiltersState.activeFiltersTypes)
  public activeFilterTypes$!: Observable<FilterType[]>;

  @Select(FiltersState.filterModel)
  public filterModel$!: Observable<FilterInputModel>;

  @Select(DashboardState.dealer)
  public dealer$!: Observable<Dealer>;

  @ViewChild('mainContainer', { read: ElementRef }) mainContainer?: ElementRef;

  public isBusy = false;

  constructor(
    private store: Store,
    private apiService: ApiService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const token = localStorage.getItem('token');
    if (!!token) {
      this.apiService.setApiKeyHeader(token);
    }

    //first load filters/dealer(product)groups
    await lastValueFrom(
      this.store.dispatch([new LoadFilters(), new LoadDealerGroups(), new LoadDealerProductGroups()]),
    );
    //then load filters from url
    this.store.dispatch([new SelectFiltersFromRoute()]);

    this.filterModel$.pipe(debounceTime(500)).subscribe((filterModel: FilterInputModel) => {
      if (!!filterModel?.employeeEntityId) {
        this.store.dispatch(new LoadEmployeeWithCertifications(filterModel.employeeEntityId));
      } else if (!!filterModel?.dealerEntityId) {
        this.store.dispatch([
          new LoadDealerWithCertifications(filterModel.dealerEntityId, filterModel),
          new LoadEmployeesWithCertifications(filterModel.dealerEntityId, filterModel),
        ]);
      } else if (!!filterModel?.countryEntityIds || !!filterModel?.regionEntityId) {
        if (!!filterModel?.dealerGroupIds) {
          this.store.dispatch(
            new LoadDealersCertificationData(
              filterModel.regionEntityId !== undefined ? [filterModel.regionEntityId] : filterModel.countryEntityIds!,
              filterModel,
            ),
          );
        }
        this.store.dispatch(new LoadDealerGroupsWithCertifications(filterModel));
      } else if (!!filterModel) {
        this.store.dispatch(new LoadDealerGroupsWithCertifications(filterModel));
      }
    });
  }

  public removeFilter(value: Filter): void {
    this.store.dispatch(new RemoveFilters([value]));
  }

  public removeAllFilterForType(type: FilterType): void {
    // Find all filter for this type
    this.store.selectOnce(FiltersState.filtersByType(type)).forEach((filters: IFilterable[]) => {
      // Remove all filters for this type
      this.store.dispatch(new RemoveFilters(filters.filter((f) => f.selected)));
    });
  }

  public async exportPDF(): Promise<void> {
    this.mainContainer?.nativeElement.classList.add('pdf-export');
    this.isBusy = true;

    await html2pdf()
      .set({
        margin: 0.2,
        filename: `${this.getExportFileName()}.pdf`,
        jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
      })
      .from(this.mainContainer?.nativeElement)
      .save();

    this.mainContainer?.nativeElement.classList.remove('pdf-export');
    this.isBusy = false;
  }

  private getExportFileName(): string {
    return 'Yamaha Dashboard Export ' + DateTime.now().toFormat('yyyy-MM-dd');
  }

  public get title(): Observable<string> {
    return this.activeFilters$.pipe(
      map((filters: Partial<FilterList>): string => {
        // Dealers
        const parts: string[] = [];
        const dealerFilter = filters[FilterType.Dealer]
          ?.filter((f) => f.selected)
          .map((f) => f.name)
          .join(', ');
        if (!!dealerFilter) {
          parts.push(dealerFilter);

          // Employees
          const employeeFilter = filters[FilterType.Employee]
            ?.filter((f) => f.selected)
            .map((f) => f.name)
            .join(', ');
          if (!!employeeFilter) {
            parts.push(employeeFilter);
          }
        } else {
          // Countries
          const countryFilter = filters[FilterType.Country]
            ?.filter((f) => f.selected)
            .map((f) => f.name)
            .join(', ');
          if (!!countryFilter) {
            parts.push(countryFilter);
          } else {
            parts.push('Worldwide');
          }
          // Dealer groups
          const dealerGroupFilter = filters[FilterType.DealerGroup]
            ?.filter((f) => f.selected)
            .map((f) => f.name)
            .join(', ');
          if (!!dealerGroupFilter) {
            parts.push(dealerGroupFilter);
          }
        }

        return parts.join(' - ');
      }),
    );
  }
}
