export enum EnrollmentStatus {
  waiting = 'Waiting',
  toBeConfirmed = 'To Be Confirmed',
  enrolled = 'Enrolled',
  inProgress = 'In Progress',
  completed = 'Completed',
  suspended = 'Suspended',
  overbooking = 'Overbooking',
  unknown = 'Unknown',
}

export function getEnrollmentStatusByString(value: string): EnrollmentStatus {
  switch (value) {
    case 'Waiting':
      return EnrollmentStatus.waiting;
    case 'To Be Confirmed':
      return EnrollmentStatus.toBeConfirmed;
    case 'Enrolled':
      return EnrollmentStatus.enrolled;
    case 'In Progress':
      return EnrollmentStatus.inProgress;
    case 'Completed':
      return EnrollmentStatus.completed;
    case 'Suspended':
      return EnrollmentStatus.suspended;
    case 'Overbooking':
      return EnrollmentStatus.overbooking;
    default:
      return EnrollmentStatus.unknown;
  }
}
