<div class="container" #mainContainer>
  <div class="row">
    <div class="col mt-5">
      <h1>Dashboard - {{ title | async }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-3">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col-12 col-md-9" id="pdf">
      <div class="content-box active-filters mt-5 rounded">
        <div class="container">
          <div class="row">
            <div class="col-11">
              <div class="row" *ngIf="activeFilterTypes$ | async as activeFilterTypes">
                <div class="col-auto my-2" *ngFor="let filterType of activeFilterTypes">
                  <app-filter-box
                    [type]="filterType"
                    (closeFilter)="removeAllFilterForType(filterType)"></app-filter-box>
                </div>
              </div>
            </div>
            <div class="col-1">
              <app-icon-button (click)="exportPDF()" src="/assets/pdf.svg" alt="Export to PDF"></app-icon-button>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <app-general-data></app-general-data>

        <app-dealer-data></app-dealer-data>

        <app-employee-data></app-employee-data>
      </div>
    </div>
  </div>
</div>

<div class="busy-indicator" *ngIf="isBusy">
  <div class="indicator">
    <div class="inner-indicator">
      <div></div>
      <div></div>
    </div>
  </div>
</div>
