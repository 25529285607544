import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { LoadCourses } from '@core/states/courses/courses.actions';
import { CoursesStateModel } from '@core/states/courses/courses.state-model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Course } from '@shared/models';
import { Observable, tap } from 'rxjs';

@State<CoursesStateModel>({
  name: 'courses',
  defaults: {
    courses: [],
  },
})
@Injectable()
export class CoursesState {
  public constructor(private apiService: ApiService) {}

  @Selector()
  static courses(state: CoursesStateModel): Course[] {
    return state.courses;
  }

  @Action(LoadCourses)
  public loadCourses(ctx: StateContext<CoursesStateModel>): Observable<Course[]> {
    return this.apiService.courses().pipe(
      tap((courses: Course[]): void => {
        ctx.patchState({ courses });
      }),
    );
  }
}
