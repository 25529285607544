import { Type } from 'class-transformer';

import { Certification } from './certification.model';
import { CertificationGroup } from './certification-group.model';

export class CertificationDealerData {
  @Type(() => Certification)
  public certifications!: Certification[];
  @Type(() => CertificationGroup)
  public certificationGroup!: CertificationGroup;

  public certifiedEmployees!: number;
  public totalEnrolledEmployees!: number;
}
