import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadDealersBy, LoadDealerTypes, LoadEmployeesByDealer, LoadRegionsByCountries } from '@core/states';
import { AddFilters, FiltersState, RemoveFilters } from '@core/states/filters';
import { areaTree, productTree } from '@misc/filter-node';
import { Store } from '@ngxs/store';
import { FilterType } from '@shared/enums';
import { IFilterable } from '@shared/interfaces';
import { Filter } from '@shared/models';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ListItem } from 'ng-multiselect-dropdown/multiselect.model';
import { firstValueFrom, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss'],
})
export class MultiselectDropdownComponent<T extends IFilterable> implements OnInit, OnDestroy {
  @Input() placeholder = '';
  @Input() filterType: FilterType = FilterType.Default;

  @Input() valueKey = 'id';
  @Input() labelKey = 'name';

  @Input() isMultiselect = false;

  filters$!: Observable<T[]>;
  activeFilters$!: Observable<Filter[]>;

  filterChangeActions: (AddFilters | RemoveFilters)[] = [];

  public dropdownSettings!: IDropdownSettings;

  selectedItems: T[] = [];

  private sub: Subscription = new Subscription();

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    this.dropdownSettings = {
      singleSelection: !this.isMultiselect,
      idField: this.valueKey,
      textField: this.labelKey,
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: !this.isMultiselect,
    };

    this.filters$ = this.store.select<T[]>(FiltersState.filtersByType<T>(this.filterType));
    this.activeFilters$ = this.store.select<Filter[]>(FiltersState.activeFiltersArray);

    this.sub = this.filters$.subscribe((items: T[]): void => {
      this.selectedItems = items.filter((item: T): boolean => item.selected) as unknown as T[];
    });
  }

  async onDropDownClose(): Promise<void> {
    // Delay this event handler until filterChangeActions is updated for sure. Fixes race condition.
    await new Promise((resolve) => setTimeout(resolve, 0));

    if (this.filterChangeActions.length === 0) return;

    this.filterChangeActions.forEach((action: AddFilters | RemoveFilters): void => {
      this.store.dispatch(action);
    });

    switch (this.filterType) {
      case FilterType.DealerProductGroup:
        this.store.dispatch(new LoadDealersBy());
        this.store.dispatch(new LoadDealerTypes());
        break;
      case FilterType.Country:
        this.store.dispatch(new LoadRegionsByCountries());
        this.store.dispatch(new LoadDealersBy());
        this.store.dispatch(new LoadDealerTypes());
        break;
      case FilterType.Region:
        this.store.dispatch(new LoadDealersBy());
        this.store.dispatch(new LoadDealerTypes());
        break;
      case FilterType.DealerType:
        this.store.dispatch(new LoadDealersBy());
        break;
      case FilterType.Dealer:
        this.store.dispatch(new LoadEmployeesByDealer());
        break;
    }

    this.filterChangeActions.length = 0;
  }

  async onItemToggle(item: ListItem, selected: boolean): Promise<void> {
    const items: T[] = await firstValueFrom(this.filters$);
    const data: IFilterable[] = [items.find((i: T): boolean => i.id === item.id) as T];

    const activeFilters: IFilterable[] = await firstValueFrom(this.activeFilters$);
    const children: FilterType[] | undefined =
      areaTree.findByType(this.filterType)?.children ?? productTree.findByType(this.filterType)?.children;

    if (children && children.length > 0 && !selected) {
      data.push(...activeFilters.filter((i: IFilterable): boolean => children.includes(i.filterSettings.type)));
    }

    this.filterChangeActions.push(selected ? new AddFilters(data) : new RemoveFilters(data));
    // Don't commit the filter changes yet, as the dropdown will close and the user may want to make more changes.
  }

  async onToggleAll(selected: boolean): Promise<void> {
    const items: T[] = await firstValueFrom(this.filters$);
    this.store.dispatch(selected ? new AddFilters(items) : new RemoveFilters(items));
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
