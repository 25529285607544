import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CertificationsState } from '@core/states/certifications';
import { CountriesState } from '@core/states/countries';
import { CoursesState } from '@core/states/courses';
import { DashboardState } from '@core/states/dashboard';
import { DealerGroupsState } from '@core/states/dealer-groups';
import { DealerProductGroupsState } from '@core/states/dealer-product-groups';
import { DealersState } from '@core/states/dealers';
import { EmployeesState } from '@core/states/employees';
import { FiltersState } from '@core/states/filters';
import { LearningPlansState } from '@core/states/learning-plans';
import { RegionsState } from '@core/states/regions';
import { environment } from '@environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';

const states = [
  CertificationsState,
  CountriesState,
  CoursesState,
  DealersState,
  EmployeesState,
  FiltersState,
  LearningPlansState,
  DealerGroupsState,
  DealerProductGroupsState,
  RegionsState,
  DashboardState,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forRoot(states, {
      developmentMode: !environment.production,
      selectorOptions: { suppressErrors: false, injectContainerState: false },
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
})
export class CoreModule {}
