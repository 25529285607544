<ng-container *ngIf="hasSelectedDealer | async">
  <ng-container *ngIf="(isLoading$ | async) === false; else loadingIndicator">
    <div class="content-box mt-5 rounded">
      <ng-container>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <app-table
                title="Dealer certification status"
                [columns]="dealersCertificationStatusTable"
                [data]="dealerCertificationData"
                [enableExportToExcel]="true"></app-table>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="content-box mt-5 rounded">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <app-table
              title="Employee certification status"
              [columns]="employeeCertificationTable"
              [data]="(employeesWithCertification$ | async)!"
              [filters]="tableFilters"
              [enableExportToExcel]="true"
              [onRowClicked]="onEmployeeRowClicked"></app-table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingIndicator><app-loading-indicator /></ng-template>
