export class FilterInputModel {
  public dealerId?: string;
  public dealerGroupIds?: string[];
  public dealerProductGroupIds?: string[];
  public certificationIds?: string[];
  public countryEntityIds?: string[];
  public regionEntityId?: string;
  public dealerTypes?: string[];
  public dealerEntityId?: string;
  public employeeEntityId?: string;

  public get hasEntityData(): boolean {
    return (
      this.countryEntityIds !== undefined ||
      this.regionEntityId !== undefined ||
      this.dealerEntityId !== undefined ||
      this.employeeEntityId !== undefined
    );
  }

  public get entityIds(): string[] | undefined {
    if (this.employeeEntityId !== undefined) return [this.employeeEntityId];
    if (this.dealerEntityId !== undefined) return [this.dealerEntityId];
    if (this.regionEntityId !== undefined) return [this.regionEntityId];
    if (this.countryEntityIds !== undefined) return this.countryEntityIds;
    return undefined;
  }
}
