export class DealerCertificationData {
  public certificationGroupName!: string;
  public enrolledEmployees!: number;
  public certififiedLevelOne!: number;
  public certififiedLevelTwo?: number;

  public textColorEnrolled?: string;
  public textColorLevelOne?: string;
  public textColorLevelTwo?: string;
}
