import { Component, Input, TemplateRef } from '@angular/core';
@Component({
  selector: 'app-tooltip-icon',
  templateUrl: './tooltip-icon.component.html',
  styleUrls: ['./tooltip-icon.component.scss'],
})
export class TooltipIconComponent {
  @Input() src = 'assets/info.svg';
  @Input() tooltip!: string | TemplateRef<any>;
  @Input() placement: string = 'auto';
  @Input() tooltipClass: string = '';
}
