import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadCertifications } from '@core/states';
import { AddFilters, FiltersState, LoadDealerProductGroups, LoadDealersBy, RemoveFilters } from '@core/states';
import { Store } from '@ngxs/store';
import { FilterType } from '@shared/enums';
import { IFilterable } from '@shared/interfaces';
import { Filter } from '@shared/models';
import { firstValueFrom, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
})
export class SelectButtonComponent<T extends IFilterable> implements OnInit, OnDestroy {
  @Input() placeholder = '';
  @Input() filterType: FilterType = FilterType.Default;

  @Input() valueKey = 'id';
  @Input() labelKey = 'name';

  filters$!: Observable<T[]>;
  activeFilters$!: Observable<Filter[]>;

  selectedItems: T[] = [];

  private sub: Subscription = new Subscription();

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    this.filters$ = this.store.select<T[]>(FiltersState.filtersByType<T>(this.filterType));
    this.activeFilters$ = this.store.select<Filter[]>(FiltersState.activeFiltersArray);

    this.sub = this.filters$.subscribe((items: T[]): void => {
      this.selectedItems = items.filter((item: T): boolean => item.selected) as unknown as T[];
    });
  }

  async onItemToggle(item: IFilterable): Promise<void> {
    const items: T[] = await firstValueFrom(this.filters$);
    const data: T = items.find((i: T): boolean => i.id === item.id) as T;

    this.store.dispatch(!data.selected ? new AddFilters([data]) : new RemoveFilters([data]));

    switch (this.filterType) {
      case FilterType.DealerGroup:
        this.store.dispatch(new LoadDealersBy());
        this.store.dispatch(new LoadCertifications());
        this.store.dispatch(new LoadDealerProductGroups());
        break;
    }
  }

  async onToggleAll(selected: boolean): Promise<void> {
    const items: T[] = await firstValueFrom(this.filters$);
    this.store.dispatch(selected ? new AddFilters(items) : new RemoveFilters(items));
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
