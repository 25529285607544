import { Component, Input } from '@angular/core';

// This component displays an array of strings as comma separated.
// If the array is longer than the maxDisplayCount, the list is truncated and an overflow indicator is shown.
// If you pass ["MyFirstItem", "MySecondItem", "MyThirdItemButVeeeeeeryLong", "MyForthItem"] it will show:
// MyFirstItem, MySecondItem, MyThi...yLong, +1
@Component({
  selector: 'app-array-display',
  templateUrl: './array-display.component.html',
  styleUrls: ['./array-display.component.scss'],
})
export class ArrayDisplayComponent {
  @Input() values: string[] = [];
  @Input() maxDisplayCount: number = 3;
  @Input() maxValueLength = 15;

  get truncatedValues(): string[] {
    if (this.values.length == 1) {
      return this.values;
    } else if (this.values.length <= this.maxDisplayCount) {
      return this.values.map((value) => this.truncate(value, this.maxValueLength));
    } else {
      const displayedValues = this.values.slice(0, this.maxDisplayCount);
      return displayedValues.map((value) => this.truncate(value, this.maxValueLength));
    }
  }

  get overflowItems(): string[] | null {
    if (this.values.length > this.maxDisplayCount) {
      return this.values.slice(this.maxDisplayCount);
    } else {
      return null;
    }
  }

  private truncate(value: string, maxLength: number): string {
    if (value.length <= maxLength) {
      return value;
    } else {
      const halfLength = maxLength / 2;
      return value.substr(0, halfLength) + '...' + value.substr(value.length - halfLength);
    }
  }
}
