import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { CertificationsStateModel, LoadCertifications } from '@core/states/certifications';
import { AddFilterData, FiltersState } from '@core/states/filters';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FilterType } from '@shared/enums';
import { Certification } from '@shared/models';
import { Observable, tap } from 'rxjs';

@State<CertificationsStateModel>({
  name: 'certifications',
  defaults: {
    certifications: [],
  },
})
@Injectable()
export class CertificationsState {
  constructor(
    private apiService: ApiService,
    private store: Store,
  ) {}

  @Selector()
  static certifications(state: CertificationsStateModel): Certification[] {
    return state.certifications;
  }

  @Action(LoadCertifications)
  public loadCertifications(
    ctx: StateContext<CertificationsStateModel>,
    { dealerGroupIds }: LoadCertifications,
  ): Observable<Certification[]> {
    dealerGroupIds ??= this.store
      .selectSnapshot(FiltersState.activeFiltersByType(FilterType.DealerGroup))
      .map((f) => f.id);

    return this.apiService.certifications(dealerGroupIds).pipe(
      tap((certifications: Certification[]): void => {
        ctx.patchState({ certifications });
        this.store.dispatch(new AddFilterData(FilterType.Certification, certifications));
      }),
    );
  }
}
