<ng-container *ngIf="hasSelectedEmployee | async">
  <ng-container *ngIf="(isLoading$ | async) === false; else loadingIndicator">
    <ng-container *ngIf="employeeWithCertification$ | async as employeeWithCertification">
      <div class="content-box mt-5 rounded">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <app-employee-block [employee]="employeeWithCertification" />
            </div>
          </div>
        </div>
      </div>

      <div class="content-box mt-5 rounded">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <app-course-status-by-certification-block [employeeCourses]="employeeWithCertification.courses!" />
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loadingIndicator><app-loading-indicator /></ng-template>
