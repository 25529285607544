import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '@shared/shared.module';

import { DealerDataComponent } from './components/dealer-data/dealer-data.component';
import { EmployeeDataComponent } from './components/employee-data/employee-data.component';
import { GeneralDataComponent } from './components/general-data/general-data.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

@NgModule({
  declarations: [DashboardComponent, GeneralDataComponent, DealerDataComponent, EmployeeDataComponent],
  imports: [CommonModule, CoreModule, DashboardRoutingModule, SharedModule, FontAwesomeModule, NgOptimizedImage],
})
export class DashboardModule {}
