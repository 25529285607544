import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ContentAlignment, TableData, TableFilter, TableFilterOption, TableHeader } from '@shared/classes';
import { ExpiryType } from '@shared/enums';
import { EmployeeCourse } from '@shared/models';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-course-status-by-certification-block',
  templateUrl: './course-status-by-certification-block.component.html',
  styleUrls: ['./course-status-by-certification-block.component.scss'],
})
export class CourseStatusByCertificationBlockComponent implements OnInit {
  @Input() employeeCourses!: EmployeeCourse[];

  public courseStatusByCertificationTable: TableData<EmployeeCourse> = new TableData<EmployeeCourse>([
    new TableHeader('Certification', (item: EmployeeCourse): string => item.course?.learningPlan?.name ?? '', [], {
      align: ContentAlignment.START,
    }),
    new TableHeader('Course Name', (item: EmployeeCourse): string => item.course!.name, [], {
      align: ContentAlignment.START,
    }),
    new TableHeader('Enrollment Status', (item: EmployeeCourse): string => item.enrollmentStatus ?? '', [], {
      align: ContentAlignment.START,
    }),
    new TableHeader('Enrollment Date', (item: EmployeeCourse): Date | null => item.enrolledAt ?? null, [], {
      align: ContentAlignment.START,
      noWrap: true,
      aliceTheDisplayValueBuilder: (_: EmployeeCourse, value: Date | null): string => {
        if (value == null) return '';
        return DateTime.fromISO(value!.toString()).toFormat('dd-MM-yyyy') ?? '';
      },
    }),
    new TableHeader('Completion Date', (item: EmployeeCourse): Date | null => item.completedAt ?? null, [], {
      align: ContentAlignment.START,
      noWrap: true,
      aliceTheDisplayValueBuilder: (_: EmployeeCourse, value: Date | null): string => {
        if (value == null) return '';
        return DateTime.fromISO(value!.toString()).toFormat('dd-MM-yyyy') ?? '';
      },
    }),
    new TableHeader(
      'Deadline',
      (item: EmployeeCourse): Date | null => {
        if (item.course!.expiryTime !== null && item.enrolledAt !== null) {
          if (item.course!.expiryTimeType == ExpiryType.enrollmentDate && item.course!.expiryTime > 0) {
            return DateTime.fromISO(item.enrolledAt!.toString())
              .plus({ days: item.course!.expiryTime ?? 0 })
              .toJSDate();
          }
        }
        return null;
      },
      [],
      {
        noWrap: true,
        aliceTheDisplayValueBuilder: (_: EmployeeCourse, value: Date | null): string => {
          if (value == null) return '';
          return DateTime.fromJSDate(value).toFormat('dd-MM-yyyy') ?? '';
        },
        textColorCallback: (item: EmployeeCourse): string => {
          if (item.course!.expiryTime !== null && item.enrolledAt !== null) {
            if (item.course!.expiryTimeType == ExpiryType.enrollmentDate && item.course!.expiryTime > 0) {
              const deadLine = DateTime.fromISO(item.enrolledAt!.toString()).plus({
                days: item.course!.expiryTime ?? 0,
              });
              let completedAt = DateTime.now();
              if (item.completedAt != null) {
                completedAt = DateTime.fromISO(item.completedAt.toString());
              }
              if (completedAt > deadLine) return 'red';
            }
          }
          return '';
        },
      },
    ),
  ]);

  public tableFilters: TableFilter<EmployeeCourse>[] = [];

  constructor(private datePipe: DatePipe) {}

  public ngOnInit(): void {
    const certificationNames = Array.from(
      new Set((this.employeeCourses ?? []).map((c) => c.course?.learningPlan?.name ?? '')),
    );

    this.tableFilters = [
      new TableFilter<EmployeeCourse>('certification', [
        new TableFilterOption('Certification', (): boolean => true),
        ...certificationNames.map(
          (name) =>
            new TableFilterOption(name, (e: EmployeeCourse): boolean => {
              return (e.course?.learningPlan?.name ?? '').startsWith(name);
            }),
        ),
      ]),
      new TableFilter<EmployeeCourse>('completion', [
        new TableFilterOption('All', (): boolean => true),
        new TableFilterOption('Completed', (e: EmployeeCourse): boolean => e.completedAt != null),
        new TableFilterOption('Not completed', (e: EmployeeCourse): boolean => e.completedAt == null),
      ]),
    ];
  }
}
