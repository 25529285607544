import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CertificationGroup, DealerGroup } from '@shared/models';

@Component({
  selector: 'app-certification-stats-block',
  templateUrl: './certification-stats-block.component.html',
  styleUrls: ['./certification-stats-block.component.scss'],
})
export class CertificationStatsBlockComponent implements OnChanges {
  @Input() certificationGroup!: CertificationGroup;
  @Input() productGroup!: DealerGroup;
  @Input() isFirstBlock = false;

  public valueColors: { [key: string]: string[] } = {
    PTW: ['#fad3d3', '#f5a8a8', '#f27c7c', '#ed5151', '#e82525', '#bd170c'],
    MARINE: ['#d7e9f0', '#afd4e1', '#86bed3', '#8fc3d6', '#73b4cc', '#578ca5'],
    SPV: ['#ffebcc', '#ffd899', '#ffc466', '#ffb133', '#ff9d00', '#d17a07'],
    RV: ['#d8f0d7', '#b1e1af', '#89d386', '#62c45e', '#3bb536', '#329219'],
  };

  public getPercentage(count: number): number {
    return (count / (this.productGroup.totalDealers ?? 1)) * 100;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('certificationGroup' in changes) {
      this.certificationGroup = changes['certificationGroup'].currentValue;
    }
  }
}
