import { FilterSelectionType, FilterType } from '@shared/enums';
import { IFilterable, IFilterSettings } from '@shared/interfaces';

export class Country implements IFilterable {
  public id!: string;
  public name!: string;
  public entityId!: string;
  public selected!: boolean;

  public filterSettings: IFilterSettings = {
    type: FilterType.Country,
    selectionType: FilterSelectionType.Multi,
  };
}
