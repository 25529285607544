import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { EmployeesStateModel, LoadEmployees, LoadEmployeesByDealer } from '@core/states/employees';
import { AddFilterData, FiltersState } from '@core/states/filters';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FilterType } from '@shared/enums';
import { Employee } from '@shared/models';
import { Observable, tap } from 'rxjs';

@State<EmployeesStateModel>({
  name: 'employees',
  defaults: {
    employees: [],
  },
})
@Injectable()
export class EmployeesState {
  constructor(
    private apiService: ApiService,
    private store: Store,
  ) {}

  @Selector()
  static employees(state: EmployeesStateModel): Employee[] {
    return state.employees;
  }

  @Action(LoadEmployees)
  public loadEmployees(ctx: StateContext<EmployeesStateModel>): Observable<Employee[]> {
    return this.apiService.employees().pipe(
      tap((employees: Employee[]): void => {
        ctx.patchState({ employees });
      }),
    );
  }

  @Action(LoadEmployeesByDealer)
  public loadEmployeesByDealer(
    ctx: StateContext<EmployeesStateModel>,
    { dealerId }: LoadEmployeesByDealer,
  ): Observable<Employee[]> {
    dealerId ??= this.store.selectSnapshot(FiltersState.activeFiltersByType(FilterType.Dealer))[0]?.id;

    return this.apiService.employeesByDealer(dealerId).pipe(
      tap((employees: Employee[]): void => {
        ctx.patchState({ employees });
        this.store.dispatch(new AddFilterData(FilterType.Employee, employees));
      }),
    );
  }
}
