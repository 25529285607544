import { Component, Input } from '@angular/core';
import { EnrollmentStatus, getEnrollmentStatusByString } from '@shared/enums';
import { Employee, EmployeeCourse } from '@shared/models';

@Component({
  selector: 'app-employee-block',
  templateUrl: './employee-block.component.html',
  styleUrls: ['./employee-block.component.scss'],
})
export class EmployeeBlockComponent {
  @Input() employee!: Employee;

  toBeginColor = 'cornflowerblue';
  enrolledColor = 'crimson';
  completedColor = 'mediumseagreen';

  public get completedCertifications(): string {
    return this.employee
      .certifications!.filter((c) => c.completedAt !== null)
      .map((c) => c.certification!.name)
      .join('\n');
  }

  public get employeeRoles(): string {
    return this.employee.roles!.map((r) => r.name).join('\n');
  }

  public get employeeProducts(): string {
    return this.employee.productGroups!.map((p) => p.name).join(', ');
  }

  public get totalCourses(): number {
    return (this.employee.courses ?? []).length;
  }

  public get courseGraphData(): { [key: string]: number } {
    const uniqueCourses: { [key: string]: EmployeeCourse } = {};

    const data: { [key: string]: number } = {};
    for (const course of this.employee.courses ?? []) {
      if (!Object.keys(uniqueCourses).includes(course.course!.id)) {
        uniqueCourses[course.course!.id] = course;
        const enrollmentStatus = course.enrollmentStatus!.toString();
        if (Object.keys(data).includes(enrollmentStatus)) {
          data[enrollmentStatus] = data[enrollmentStatus]! + 1;
        } else {
          data[enrollmentStatus] = 1;
        }
      }
    }

    return data;
  }

  public get courseGraphLabels(): string[] {
    return Object.keys(this.courseGraphData);
  }

  public get courseGraphValues(): number[] {
    return Object.values(this.courseGraphData);
  }

  public get courseGraphColors(): string[] {
    return Object.keys(this.courseGraphData).map((c) => this.courseStatusColor(c));
  }

  public courseStatusColor(status: string): string {
    const enrollmentStatus = getEnrollmentStatusByString(status);
    switch (enrollmentStatus) {
      case EnrollmentStatus.enrolled:
        return this.enrolledColor;
      case EnrollmentStatus.completed:
        return this.completedColor;
      default:
        return this.toBeginColor;
    }
  }
}
