import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { LoadLearningPlans } from '@core/states/learning-plans/learning-plans.actions';
import { LearningPlansStateModel } from '@core/states/learning-plans/learning-plans.state-model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LearningPlan } from '@shared/models';
import { Observable, tap } from 'rxjs';

@State<LearningPlansStateModel>({
  name: 'learningPlans',
  defaults: {
    learningPlans: [],
  },
})
@Injectable()
export class LearningPlansState {
  public constructor(private apiService: ApiService) {}

  @Selector()
  static learningPlans(state: LearningPlansStateModel): LearningPlan[] {
    return state.learningPlans;
  }

  @Action(LoadLearningPlans)
  public loadLearningPlans(ctx: StateContext<LearningPlansStateModel>): Observable<LearningPlan[]> {
    return this.apiService.learningPlans().pipe(
      tap((learningPlans: LearningPlan[]): void => {
        ctx.patchState({ learningPlans });
      }),
    );
  }
}
