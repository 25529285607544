export class Authenticate {
  public static readonly type = '[Auth] Authenticate';

  public constructor(
    public readonly userId: string,
    public readonly username: string,
    public readonly authCode: string,
    public readonly hash: string,
  ) {}
}

export class AuthenticateLogin {
  public static readonly type = '[Auth] Authenticate with login';

  public constructor(
    public readonly username: string,
    public readonly password: string,
  ) {}
}

export class RefreshToken {
  public static readonly type = '[Auth] Refresh token';
}
