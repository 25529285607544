import { EnrollmentStatus } from '@shared/enums';
import { Course } from '@shared/models/course.model';
import { Type } from 'class-transformer';

export class EmployeeCourse {
  @Type(() => Course)
  public course?: Course;
  public enrolledAt?: Date;
  public completedAt?: Date;
  public enrollmentStatus?: EnrollmentStatus;
}
